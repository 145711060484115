import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ProductCardLines, { cardData } from './ProductCardLines'
import {
  Container,
  Menu,
  Header,
  Icon
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

class PageCatalog extends Component {


  handleClickPartGroup = (e, id, urlName) => {
    const { openPartGroupPageByUrlName, openPartGroupPageById } = this.props.shop.view;
    if (id)
      openPartGroupPageById(id);
    else
      openPartGroupPageByUrlName(urlName);
  };

  getAnchor = () => {
          var currentUrl = document.URL,
        urlParts   = currentUrl.split('#');

          return (urlParts.length > 1) ? urlParts[1] : null;
  };

  componentDidUpdate() {
    const element = document.getElementById(this.getAnchor());

    if (element)
      element.scrollIntoView({behavior: 'smooth'});
  }

  render() {
    return(
      <div>
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='book' />
            <Header.Content>Catalog</Header.Content>
          </Header>
          <ProductCardLines lines={cardData} onClickPartGroup={this.handleClickPartGroup} />
        </Container>
        {!this.getAnchor() && <ScrollToTopOnMount />}
      </div>
    )
  }
}

export default inject("shop")(observer(PageCatalog))