import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Form,
  Button,
  Segment,
  Message,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import MaskedInput from 'react-text-mask'
import ScrollToTopOnMount from './ScrollToTopOnMount'


class PageRFQ extends Component {

  state = {
    quoteRequested: false,
    quoteComplete: false,
    errorReturned: null
  }

  handleRemoveClick = (e, entry) => {
    e.preventDefault();
    const { cart } = this.props.shop;
    cart.removePart(entry.part.partNumber);
    return false;
  }

  handleQuantity1Update = (e, entry) => {
    if (e.target.value) entry.setQuantity1(parseInt(e.target.value, 10))
  }

  handleQuantity2Update = (e, entry) => {
    if (e.target.value) entry.setQuantity2(parseInt(e.target.value, 10))
  }

  handleQuantity3Update = (e, entry) => {
    if (e.target.value) entry.setQuantity3(parseInt(e.target.value, 10))
  }

  handleContinueShopping = (e) => { }

  handleRequestQuote = (e, entry) => {
    e.preventDefault();
    const { cart } = this.props.shop;

    this.setState({ quoteRequested: true, errorReturned: null });

    cart.requestQuote().then((result) => {
      this.setState({ quoteComplete: true });
      window.scrollTo(0, 0);
    }).catch((err) => {
      let errorMessage = 'An unknown error occurred'

      console.log('ERROR:', err);

      if (err.constructor.name === 'AppError')
        errorMessage = err.errors[0].message;

      this.setState({
        quoteRequested: false,
        errorReturned: errorMessage
      });
    });
    return false;
  }

  renderEntry(entry, index) {
    const attributeArr = [];
    const attrKeys = Object.keys(entry.part.selectedAttributes)

    for (const key of attrKeys) {
      attributeArr.push(key + ': ' + entry.part.selectedAttributes[key]);
    }

    attributeArr.push('Material: ' + entry.part.material.name);
    attributeArr.push('Plating: ' + entry.part.plating.name);

    return (
      <Segment key={entry.part.partNumber + '_' + index}>
        <Form.Group>
          <div className="three wide field">
            <label>P/N</label>
            <div className="ui input">{entry.part.partNumber}</div>
          </div>
          <div className="five wide field">
            <label>Description</label>
            <div className="ui input">{entry.part.description}<br />{attributeArr.join(', ')}</div>
          </div>
          <Form.Input label="Qty1" width={2} value={entry.quantity1} onChange={(e) => this.handleQuantity1Update(e, entry)} />
          <Form.Input label="Qty2" width={2} value={entry.quantity2} onChange={(e) => this.handleQuantity2Update(e, entry)} />
          <Form.Input label="Qty3" width={2} value={entry.quantity3} onChange={(e) => this.handleQuantity3Update(e, entry)} />
          <div className="two wide field">
            <label>&nbsp;</label>
            <div className="ui input"><Form.Button onClick={(e) => this.handleRemoveClick(e, entry)}>Remove</Form.Button></div>
          </div>
        </Form.Group>
      </Segment>)
  }


  render() {
    const { cart } = this.props.shop;
    const { openCatalogPage } = this.props.shop.view;
    const { quoteComplete, quoteRequested, errorReturned } = this.state;

    return (
      <div>
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='boxes' />
            <Header.Content>RFQ</Header.Content>
          </Header>
          {!quoteComplete && <Form>

            <Dimmer active={quoteRequested} inverted>
              <Loader inverted content='Loading' />
            </Dimmer>
            <Form.Input label='Company Name' placeholder='Company Name' width={16} value={cart.company} onChange={(e) => cart.setCompany(e.target.value)} />
            <Form.Group>
              <Form.Input label='First name' placeholder='First Name' width={8} value={cart.firstName} onChange={(e) => cart.setFirstName(e.target.value)} />
              <Form.Input label='Last Name' placeholder='Last Name' width={8} value={cart.lastName} onChange={(e) => cart.setLastName(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Input label='Phone' placeholder='(XXX) XXX-XXXX' width={8}>
                <MaskedInput
                  mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  placeholder="(XXX) XXX-XXXX"
                  value={cart.phone}
                  onChange={(e) => cart.setPhone(e.target.value)}
                />
              </Form.Input>
              <Form.Input label='Email' placeholder='Email' width={8} value={cart.email} onChange={(e) => cart.setEmail(e.target.value)} />
            </Form.Group>
            {cart.entries.length == 0 && <Message
              icon='exclamation triangle'
              header="To get started"
              content="Visit our catalog section to begin selecting parts you'd like to receive a quote for."
            />}
            {cart.entries.map((entry, index) => this.renderEntry(entry, index))}
            <Form.TextArea label='Comments or Questions' placeholder='Anything else you want to tell us...' onChange={(e) => cart.setComments(e.target.value)} />
            {errorReturned && <Message negative>
              <Message.Header>We're sorry there was a problem submitting your quote</Message.Header>
              <p>{errorReturned}</p>
            </Message>}
            <Form.Group>
              <Form.Button onClick={openCatalogPage}>Continue Shopping</Form.Button>
              <Form.Button onClick={this.handleRequestQuote} disabled={!cart.canRequestQuote}>Get Quote</Form.Button>
            </Form.Group>
          </Form>}
          {quoteComplete && <Message
              icon='check'
              header="Thank you!"
              content="Your request for quote has been forwarded to our Sales Department and you will receive a response shortly."
            />}
        </Container>
        <ScrollToTopOnMount />
      </div>)
    }
}



function onEntryClick(shop, e) {
    shop.view.openItemPage(this.item)
    e.preventDefault()
    return false
}



function updateEntryQuantity(entry, e) {
    if (e.target.value) entry.setQuantity(parseInt(e.target.value, 10))
}

export default inject("shop")(observer(PageRFQ))