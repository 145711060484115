
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {
  Image,
  Card,
  Accordion,
  Icon
} from 'semantic-ui-react'

const Print = ({ print }) => (
  <li>{print.name}</li>
)


class PrintGroup extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired
  };

  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const { group } = this.props

    return (
      <Accordion fluid styled>

        {group.prints.map((print, idx) => {

          if (print.type == 'group')
            return (
              <Fragment key={'printGroup_' + idx}>
                <Accordion.Title active={activeIndex === idx} index={idx} onClick={this.handleClick}>{print.name}</Accordion.Title>
                <Accordion.Content active={activeIndex === idx}><PrintGroup group={print} /></Accordion.Content>
              </Fragment>
              )
          else
            return <Accordion.Title active={activeIndex === idx} index={idx} key={'printGroup_' + idx} onClick={()=> window.open("/drawings/" + encodeURIComponent(print.file), "_blank")}>{print.name}</Accordion.Title>
        })}
      </Accordion>
    )
  }
}


class PartGroup extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    onClickPartGroup: PropTypes.func
  };

  state = { activeIndex: -1 }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }



  render() {
    const { activeIndex } = this.state
    const { group, shop, onClickPartGroup } = this.props


    return (
      <Accordion fluid styled>

        {group.partGroups.map((partGroup, idx) => {

          if (partGroup.type == 'group')
            return (
              <Fragment key={'partGroup_' + idx}>
                <Accordion.Title active={activeIndex === idx} index={idx} onClick={this.handleClick}>{partGroup.name}</Accordion.Title>
                <Accordion.Content active={activeIndex === idx}><PartGroup group={partGroup} onClickPartGroup={onClickPartGroup} /></Accordion.Content>
              </Fragment>
              )
          else
            return <Accordion.Title active={activeIndex === idx} index={idx} key={'partGroup_' + idx} onClick={ (e) => onClickPartGroup(e, partGroup.id, partGroup.urlName) }>{partGroup.name}</Accordion.Title>
        })}
      </Accordion>
    )
  }
}


class ProductCard extends Component {
  static propTypes = {
    card: PropTypes.object.isRequired,
    showPrints: PropTypes.bool,
    line: PropTypes.string,
    onClickPartGroup: PropTypes.func.isRequired,
  };



  render() {

    const { line, card, showPrints, onClickPartGroup } = this.props;

    return (
      <Card>
        <Image src={card.image} centered={true} id={line + card.name.replace(/ /g,'')} />
        <Card.Content>
          <Card.Header>
            {card.name}
          </Card.Header>
          { !showPrints && <Card.Description>
            { /* card.types && <div>{card.types}</div> */}
            { /* card.types2 && <div>{card.types2}</div> */}
            {card.materials && <div style={{padding: '5px'}}>{card.materials}</div>}
            <PartGroup group={card} onClickPartGroup={onClickPartGroup} />
            {/* card.sizes && <div>{card.sizes}</div> */}
          </Card.Description> }
          { showPrints && <Card.Description>
            <PrintGroup group={card} />
          </Card.Description> }
        </Card.Content>
      </Card>
    )
  }
}

export default ProductCard