import { types, getEnv } from "mobx-state-tree"
import { reaction } from "mobx"
//import { ItemStore } from "./ItemStore"
import { CartStore } from "./CartStore"
import { CrossRefStore } from "./CrossRefStore"
import { ViewStore } from "./ViewStore"
//import { PhotoStore } from "./PhotoStore"
//import { ProjectStore } from "./ProjectStore"
import { PartGroupStore } from "./PartGroupStore"
import { SiteConfigStore, } from "./SiteConfigStore"
import { InventoryStore } from "./InventoryStore"

export const ShopStore = types
    .model("ShopStore", {
      /*
        itemStore: types.optional(ItemStore, {
            items: {}
        }),
        */
        config: types.optional(SiteConfigStore, {}),
        cart: types.optional(CartStore, {
            entries: []
        }),
        crossRef: types.optional(CrossRefStore, {
            entries: [{}, {}, {}]
        }),
        partGroupStore: types.optional(PartGroupStore, {
            partGroups: {}
        }),
        inventory: types.optional(InventoryStore, { filteredInventory: []}),
        view: types.optional(ViewStore, {}),
        lastSelectedSearch: types.optional(types.string, 'Unknown'),
    })
    .views(self => ({
        get fetch() {
            return getEnv(self).fetch
        },
        get alert() {
            return getEnv(self).alert
        },
        get isLoading() {
            return self.partGroupStore.isLoading
        },
        get isLoadingSingle() {
            return self.partGroupStore.isLoadingSingle
        },
        get isLoadingConfig() {
            return self.config.isLoading
        },
      /*
        get items() {
            return self.itemStore.items
        },
        get projects() {
            return self.projectStore.projects
        },
        */
        get partGroups() {
            return self.partGroupStore.partGroups
        },
      /*
        get sortedProjects() {
            return self.projectStore.sortedProjects
        },
        get sortedAvailableItems() {
            return self.itemStore.sortedAvailableItems
        },
        get photos() {
            return self.photoStore.photos
        },
        get sortedPhotos() {
            return self.photoStore.sortedPhotos
        },

        get opaqueModuleOutput() {
            return {
                cartTotalQuantity: self.cart && self.cart.totalQuantity ? self.cart.totalQuantity : 0,
                projectCount: self.projectStore && self.projectStore.projectCount ? self.projectStore.projectCount : 0
            }
        }
         */
    }))
    .actions(self => ({
        afterCreate() {
            //self.itemStore.loadItems();
            //self.photoStore.loadPhotos();
            //self.partGroupStore.loadPartGroups();
            self.config.loadSiteConfig().then(() => {


              if (self.config && self.config.trackingCode && self.config.trackingCode.length > 0) {

                self.config.trackingCode.forEach((code) => {
                  const newScript = document.createElement("script");
                  const inlineScript = document.createTextNode(code);
                  newScript.appendChild(inlineScript);
                  document.body.appendChild(newScript);
                });
              }

              if (self.config && self.config.trackingCodeHead && self.config.trackingCodeHead.length > 0) {

                self.config.trackingCodeHead.forEach((code) => {
                  const newScript = document.createElement("script");
                  const inlineScript = document.createTextNode(code);
                  newScript.appendChild(inlineScript);
                  document.head.appendChild(newScript);
                });
              }
            });
          /*
          self.opaqueModule = new OpaqueModule();

            self.opaqueModule.bindOutputListener((data) => {
                self.setOpaqueModuleStatus('Number of projects is ' + (data.numberOfProjectsIsEven ? 'even. ' : 'odd. ')
                    + 'Number of cart items is ' + (data.numberOfCartItemsIsEven ? 'even. ' : 'odd. '))
            });

            reaction(
                () => self.opaqueModuleOutput,
                data => {
                    self.opaqueModule.onExternalInput(data)
                }
            )
            */
        },
        setLastSelectedSearch(lastSelectedSearch) {
          self.lastSelectedSearch = lastSelectedSearch;
        },
        setOpaqueModuleStatus(status)
        {
            self.opaqueModuleStatus = status;
        }
    }))