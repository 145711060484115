import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import _ from 'underscore'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
Search
} from 'semantic-ui-react'
import './DesktopContainer.css'
import HomepageHeading from './HomepageHeading'
import Footer from './Footer'
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */


const initialState = {
    isLoading: false,
    results: [],
    value: ''
  }

class DesktopContainer extends Component {
  state = initialState

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  handleSearchChange = (e, { value }) => {
    const { searchPartGroupsByPartNumber } = this.props.shop.partGroupStore

    this.setState({ isLoading: true, value });
    const searchResults = [];

    searchPartGroupsByPartNumber(value).then((partsGroupsFound) => {

      partsGroupsFound.forEach((partGroup) => {
        searchResults.push({
          title: partGroup[1].name,
          image: "/images/partGroups/" + partGroup[1].imgSrc,
          urlName: partGroup[1].urlName
        });
      })

      this.setState({
        isLoading: false,
        value,
        results: searchResults
      })

    });
  }

  handleResultSelect = (e, { result }) => {
    const { openPartGroupPageByUrlName } = this.props.shop.view;
    const { shop } = this.props;
    const { value } = this.state;
    shop.setLastSelectedSearch(value);
    openPartGroupPageByUrlName(result.urlName);
  }

  renderNavLink = (navLink, key) => {
    const { page, openCatalogPage, openTechnicalPage, openCrossRefPage, openRFQPage } = this.props.shop.view;

    let openPage = () => {};
    let curpage = '';

    if (navLink.url)
      return <Menu.Item key={key} as='a' onClick={()=> window.location.assign(navLink.url)} >{navLink.title}</Menu.Item>
    else {
      curpage = navLink.page;
      if (navLink.page == 'rfq')
        openPage = openRFQPage;
      else if (navLink.page == 'technical')
        openPage = openTechnicalPage;
      else if (navLink.page == 'crossref')
        openPage = openCrossRefPage;
      else if (navLink.page == 'homepopup')
        openPage = () => { alert('Clicking the \'Home\' link would bring you back to your existing home page'); };
      else {
        curpage = 'catalog';
        openPage = openCatalogPage;
      }

      return <Menu.Item as='a' key={key} onClick={openPage} active={page == curpage}>{navLink.title}</Menu.Item>
    }
  };

  render() {
    const { children, shop } = this.props
    const { page, openHomePage, openCatalogPage, openTechnicalPage, openCrossRefPage, openLocalRepsPage, openCreatePrintPage, openContactUsPage, openRFQPage } = shop.view;
    const fixed = this.state.fixed || shop.view.page !== 'home';
    const { isLoading, value, results } = this.state

    return (
      <Responsive minWidth="768" className='DesktopContainer'>
        <Visibility once={false} onBottomPassed={this.showFixedMenu} onBottomPassedReverse={this.hideFixedMenu}>
          <Segment inverted textAlign='center' className='DesktopSegment' vertical>
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
              className='DesktopMenu'
            >
              <Image className='menuLogo' src='/images/logo_thin_small.png' hidden={!fixed}/>
              <Container>
                {shop.config.desktopNavLinks.map((navLink, idx) => { return this.renderNavLink(navLink, idx) })}

                <Menu.Item position='right' className='partSearch'>
                  <Search inverted={!fixed}  placeholder='Part Search...'
                    loading={isLoading}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, {
                      leading: true,
                    })}
                    results={results}
                    value={value}
                    {...this.props}/>
                </Menu.Item>
                <Image className='menuLogo' src='/images/fascomp_logo_thin_small.png' hidden={!fixed}/>
              </Container>
            </Menu>
            {(page === 'home') && <HomepageHeading />}
          </Segment>
        </Visibility>
        <div className='desktopChildren'>
          {children}
          <Footer />
        </div>
      </Responsive>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

export default inject("shop")(observer(DesktopContainer))