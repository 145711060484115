import { observer, inject } from "mobx-react"
import { observable, reaction } from "mobx"
import PropTypes from 'prop-types'
import React, { Component, useCallback } from 'react'
import _ from 'underscore'
import groupData from './fsh1-8.json'
import MetaTags from 'react-meta-tags';
import {
  Container,
  Menu,
  Header,
  Label,
  Image,
  Dropdown,
  Button,
  Form,
  Table
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'



class PagePartGroup extends Component {

  
  state = {
    part: '',
    material: '',
    plating: '',
    options: {},
    partOptions: {},
    optionAttributes: {},
    partOptionAttributes: {}
  }

  componentDidMount() {
    const { shop, partGroup } = this.props
    document.title = partGroup.name + ' - Fascomp Electronic Hardware'
    shop.inventory.clearFilteredInventory();
    this.reactionDispose = reaction(
        () => shop.lastSelectedSearch,
        search => {
            this.setOptionsFromSearch(this.props);
        }
    );

  }

  componentWillUnmount() {
    this.reactionDispose();
  }

  get canQuote() {
    const rv = this.computedPartNumber.indexOf('?') == -1 ? true : false
    console.log(rv);
    return rv;
  }

  handleMaterialChange = (e, data) => {
    const { shop } = this.props
    const { plating } = this.state;
    const option = _.findWhere(this.platingOptions(data.value), { value: plating });
    const newState = { material: data.value };

    if (!plating || !plating.length > 0 || !option)
      newState.plating = '0';

    shop.inventory.getFilteredInventory(this.computedPartNumberFromData({ ...this.state, ...newState}, true));
    this.setState(newState);
  };

  handlePlatingChange = (e, data) => {
    const { shop } = this.props

    const newState = { plating: data.value };
    shop.inventory.getFilteredInventory(this.computedPartNumberFromData({ ...this.state, ...newState}, true));
    this.setState(newState);
  };

  handleAddToQuote = (e) => {
    const { plating, material, optionAttributes, partOptionAttributes } = this.state;
    const { shop, partGroup } = this.props

    shop.cart.addPart({
      partNumber: this.computedPartNumber,
      description: partGroup.name,
      plating: { name: _.findWhere(this.platingOptions(material), {value: plating}).text, value: plating},
      material: { name: _.findWhere(this.materialOptions, {value: material}).text, value: material},
      selectedAttributes: Object.assign({}, optionAttributes, partOptionAttributes)
    });

    shop.view.openRFQPage();
  };

  handleAddInventoryToQuote = item => {
    //const { plating, material } = item;
    const { shop, partGroup } = this.props

    const plating = _.findWhere(this.platingOptions(material), {value: item.plating});
    const material = _.findWhere(this.materialOptions, {value: item.material});

    const platingText = plating ? plating.text : null;
    const materialText = material ? material.text : null;

    if (item.exactMatch)
      this.handleAddToQuote();
    else{

      shop.cart.addPart({
        partNumber: item.partNumber,
        description: partGroup.name + ' (from inventory)',
        plating: platingText ? { name: platingText, value: item.plating } : null,
        material: materialText ? { name: materialText, value: item.material} : null,
        selectedAttributes: {}
      });
      shop.view.openRFQPage();
    }
  };

  handleOptionChange = (e, data, optionSet) => {
    const { shop } = this.props

    const option = _.findWhere(optionSet.options, { value: data.value });
    let optionToUpdate = {};
    let optionAttributeToUpdate = {};

    optionToUpdate[optionSet.name] = data.value;

    if (option.optionAttributes && option.optionAttributes.length)
    {
      option.optionAttributes.forEach((attribute) => {
        optionAttributeToUpdate[attribute.name] = attribute.value;
      });
    }

    const newState = {
      options: Object.assign({}, this.state.options, optionToUpdate),
      optionAttributes: Object.assign({}, this.state.optionAttributes, optionAttributeToUpdate)
    };

    shop.inventory.getFilteredInventory(this.computedPartNumberFromData({ ...this.state, ...newState}, true));
    this.setState(newState, () => {
      console.log(this.state.options);
    });

  };

  handlePartOptionChange = (e, data, option) => {
    const { partGroup, shop } = this.props;
    const { partOptions } = this.state;
    const partOptionToUpdate = {};
    const partOptionAttributeToUpdate = {};
    const requiredOptions = _.pluck(partGroup.partOptions, 'value');
    let selectedPart;
    partOptionToUpdate[option.value] = data.value;

    const newPartOptions = Object.assign({}, partOptions, partOptionToUpdate);

    let haveRequiredOptions = true;
    requiredOptions.forEach((opt) => {
      if (!newPartOptions.hasOwnProperty(opt))
        haveRequiredOptions = false;
    });

    if (haveRequiredOptions)
    {
      for(let part of partGroup.parts) {

        let partHasOptions = true;
        Object.keys(newPartOptions).forEach((key) => {
          if (!_.findWhere(part.optionAttributes, { name: key, value: newPartOptions[key] }))
            partHasOptions = false;
        })

        if (partHasOptions)
        {
          selectedPart = part;
          break;
        }
      }
    }

    const newState = { partOptions: newPartOptions };

    if (selectedPart)
    {
      newState.part = selectedPart.partNumber;
      selectedPart.optionAttributes.forEach((attr) => {
        if (requiredOptions.indexOf(attr.name) == -1)
          partOptionAttributeToUpdate[attr.name] = attr.value;
      });
      newState.partOptionAttributes = partOptionAttributeToUpdate;
    }

    shop.inventory.getFilteredInventory(this.computedPartNumberFromData({ ...this.state, ...newState}, true));
    this.setState(newState);

    // Select out each option that needs to be set
  };


  get materialOptions() {

    const materialOptionSet = _.findWhere(groupData.optionSets, { name: 'Material' });

    const materialOptions = [];

    materialOptionSet.options.forEach((option) => {


        materialOptions.push({
          key: option.code,
          value: option.code,
          text: option.name
        });
    });

    return materialOptions;
  }

  
  platingOptions(materialArg) {
    const { partGroup } = this.props;
    let { material } = this.state;
    const platingOptionSet = _.findWhere(groupData.optionSets, { name: 'Plating' });
    const platingOptions = [];

    let options = platingOptionSet.options;

    if (partGroup.type === 'Handle') {

      options = platingOptionSet.options.concat([
        {
          rules: [
              {
                  type: "equals",
                  source: "Material",
                  value: "A"
              }
          ],
          name: "Clear Anodize (Glossy)",
          code: "34"
        },
        {
            rules: [
                {
                    type: "equals",
                    source: "Material",
                    value: "A"
                }
            ],
            name: "Black Anodize (Glossy)",
            code: "35"
        }
      ]);

      
    }

    if (materialArg)
      material = materialArg;

    options.forEach((option) => {
      let addToOptions = true;

      if (option.rules && option.rules.length) {
        for(let rule of option.rules) {

          switch(rule.type)
          {
            case 'equals':
              if (material !== rule.value)
                addToOptions = false;
              break;
            case 'oneof':
              if (rule.value.indexOf(material) == -1)
                addToOptions = false;
              break;
          }

          if (!addToOptions)
            break;
        }
      }

      if (addToOptions) {
        platingOptions.push({
          key: option.code,
          value: option.code,
          text: '(' + option.code + ') ' + option.name
        });
      }
    });

    const platingAllowed = _.pluck(platingOptions, 'value');

   // if (platingAllowed.indexOf(plating) == -1)
   //    this.setState({ plating: '?' });

    return platingOptions;
  }

  partAttributeOptions(optionName) {
    const { partGroup } = this.props;
    const { partOptions } = this.state;
    const partAttributeOptions = [];
    const selectedOptions = [];

    // Make sure all options before have been selected
    for(let option of partGroup.partOptions) {
      if (option.value == optionName)
        break;

      if (!partOptions[option.value])
        return [];

      selectedOptions.push(option);
    }

    const sortedParts = partGroup.parts.sort((opt1, opt2) => opt1.groupSort - opt2.groupSort);

    sortedParts.forEach((part) => {
      const option = _.findWhere(part.optionAttributes, { name: optionName });

      // Check that this part has prior selected options
      let hasSelectedOptions = true;
      selectedOptions.forEach((selectedOption) => {
        if (!_.findWhere(part.optionAttributes, { name: selectedOption.value, value: partOptions[selectedOption.value] }))
          hasSelectedOptions = false;
      });

      const optionExists = _.findWhere(partAttributeOptions, { value: option.value });

      if (!optionExists && hasSelectedOptions) {
        partAttributeOptions.push({
          key: partAttributeOptions.length,
          value: option.value,
          text: option.value
        })
      }
    });

    console.log(partAttributeOptions);

    return partAttributeOptions
  }

  attributeOptions(optionSet) {

    const attributeOptions = [];
    const sortedOptions = optionSet.options.sort((opt1, opt2) => opt1.sort - opt2.sort);

    sortedOptions.forEach((option) => {

      attributeOptions.push({
        key: option.value,
        value: option.value,
        text: option.name
      })

    });

    return attributeOptions
  }

  get computedPartNumber() {

    //const { partGroup } = this.props;
    //const { options, part, material, plating } = this.state;
    return this.computedPartNumberFromData(this.state);
    /*
    const pnReg = /{[a-zA-Z /]+:[?]+}/g;
    const matches = partGroup.partNumberMask.match(pnReg);

    let compPartNum = partGroup.partNumberMask;

    matches.forEach((pnTokenMask) => {
      const option = pnTokenMask.split(":")[0].replace('{', '');
      const mask = pnTokenMask.split(":")[1].replace('}', '');


      if (option == 'Part Number') {
        compPartNum = compPartNum.replace(pnTokenMask, part.length > 0 ? part : mask)
      }
      else if (option == 'Material') {
        compPartNum = compPartNum.replace(pnTokenMask, material.length > 0 ? material : mask)
      }
      else if (option == 'Plating') {
        compPartNum = compPartNum.replace(pnTokenMask, plating.length > 0 ? plating : mask)
      }
      else {
        compPartNum = compPartNum.replace(pnTokenMask, options[option] ? options[option] : mask)
      }

      if (compPartNum.substr(compPartNum.length - 2, 2) == '-0')
        compPartNum = compPartNum.substr(0, compPartNum.length - 2);

    });

    return compPartNum;
    */
  }

  computedPartNumberFromData(data, noMask) {
    const { partGroup } = this.props;
    const { options, part, material, plating } = data;

    const pnReg = /{[a-zA-Z /]+:[?]+}/g;
    const matches = partGroup.partNumberMask.match(pnReg);

    let compPartNum = partGroup.partNumberMask;

    matches.forEach((pnTokenMask) => {
      const option = pnTokenMask.split(":")[0].replace('{', '');
      const mask = pnTokenMask.split(":")[1].replace('}', '');


      if (option == 'Part Number') {
        compPartNum = compPartNum.replace(pnTokenMask, part.length > 0 ? part : mask)
      }
      else if (option == 'Material') {
        compPartNum = compPartNum.replace(pnTokenMask, material.length > 0 ? material : mask)
      }
      else if (option == 'Plating') {
        compPartNum = compPartNum.replace(pnTokenMask, plating.length > 0 ? plating : mask)
      }
      else {
        compPartNum = compPartNum.replace(pnTokenMask, options[option] ? options[option] : mask)
      }

      if (compPartNum.substr(compPartNum.length - 2, 2) == '-0')
        compPartNum = compPartNum.substr(0, compPartNum.length - 2);

    });

    if (noMask) {
      const newCompPartNumArr = [];
      for (const segment of compPartNum.split('-')) {
        if (segment.indexOf('?') > -1)
          break;

          newCompPartNumArr.push(segment);
      }

      compPartNum = newCompPartNumArr.join('-');
    }

    return compPartNum;
  }

  renderPartOption(option) {
    const { partOptions } = this.state;

    const value = partOptions.hasOwnProperty(option.value) ? partOptions[option.value] : '';

    return <div className="four wide field" key={'partOption' + option.name}>
        <label>{option.name}</label>
        <Dropdown placeholder={option.name} aria-label={option.name} selection value={value} options={this.partAttributeOptions(option.value)} onChange={(e, data) => { this.handlePartOptionChange(e, data, option)} } />
      </div>
  }

  renderOptionSet(optionSet) {
    const { options } = this.state;
    const value = options.hasOwnProperty(optionSet.name) ? options[optionSet.name] : '';


    return <div className="four wide field" key={'optionSet' + optionSet.name}>
        <label>{optionSet.name}</label>
        <Dropdown placeholder={optionSet.name} aria-label={optionSet.name}  selection value={value} options={this.attributeOptions(optionSet)} onChange={(e, data) => { this.handleOptionChange(e, data, optionSet)} } />
      </div>
  }

  renderOptionAttributes() {

    const { optionAttributes } = this.state;

    return Object.keys(optionAttributes).map((key) => {
        return <div className="four wide" key={'optionAttributes' + key}>
            {key}: {optionAttributes[key]}
          </div>
      })

  }

  renderPlatingVariants() {
    const { plating } = this.state;
    const { selectedPartNumber } = this.props;
    let partNumber = this.computedPartNumber;

    if (partNumber.indexOf('?') !== -1 || !selectedPartNumber)
        return null;

    if (plating.length > 0 && plating !== '0')
    {
      partNumber = partNumber.split('-');
      partNumber.pop();
      partNumber = partNumber.join('-');
    }

    const platingVariants = [];

    this.platingOptions().forEach((plating) => {

      platingVariants.push(<div class="eight column centered row" key={'pv-' + plating.value}>
        <div className="right aligned four wide column">
          {partNumber}{plating.value == '0' ? '' : '-' + plating.value}
        </div>
        <div className="four wide column">
          {plating.text}
        </div>
      </div>);
    });

    return (<div className="ui segment">
      <h2 className="ui center aligned header">Finish Variants</h2>
      <div className="ui eight column grid">
        {platingVariants}
      </div>
    </div>);
  }

  renderPartOptionAttributes() {

    const { partOptionAttributes } = this.state;

    return Object.keys(partOptionAttributes).map((key) => {
        return <div className="four wide" key={'poa-' + key}>
            {key}: {partOptionAttributes[key]}
          </div>
      })

  }

  sortOptionSets(partGroup) {
    console.log(partGroup);

    if (partGroup.name.indexOf('Swage Standoff') !== -1)
      return partGroup.optionSets.reverse();
    else
      return partGroup.optionSets;
  }

  get drawingForPartOptions() {
    const { partGroup } = this.props;
    const { partOptions } = this.state;

    const poReg = /\{.*?}/g;
    const matches = partGroup.drawing.match(poReg);
    let drawing = partGroup.drawing;

    if (matches) {
      matches.forEach((poToken) => {
        const partOption = poToken.replace('{', '').replace('}', '');

        if (drawing !== null && partOptions.hasOwnProperty(partOption) && partOptions[partOption].length > 0) {
          drawing = drawing.replace(poToken, partOptions[partOption]);
        }
        else {
          drawing = null;
        }
      });
    }

    return drawing;
  }

  setOptionsFromSearch(props) {
    const { partGroup, shop, selectedPartNumber } = props;
    const { options, part, material, plating } = this.state;
    const lastSelectedSearch = selectedPartNumber ? selectedPartNumber : shop.lastSelectedSearch.toUpperCase();

    const pnReg = /{[a-zA-Z /]+:[?]+}/g;
    const matches = partGroup.partNumberMask.match(pnReg);

    let compPartNum = partGroup.partNumberMask;


    let partSelected;
    // First try to find a part match
    partGroup.parts.forEach((part) => {
      if (part.partNumber == lastSelectedSearch.substr(0, part.partNumber.length))
        partSelected = part;
    });

    if (!partSelected)
      return;

    console.log('partSelected', partSelected);
    // Set the options from the selected part

    let partOptions = {};

    partSelected.optionAttributes.forEach((option) => {
      partOptions[option.name] = option.value;
    });

    // If we have a part match, subtract it from the search string and begin matching mask components
    //let searchFragment = lastSelectedSearch.substr(partSelected.partNumber.length);



    let searchArr = lastSelectedSearch.toUpperCase().split('-');
    let newPartNumber = partSelected.partNumber;
    let newOptions = {};
    let newMaterial = '';
    let newPlating = '';
    let index = 0;
    matches.forEach((pnTokenMask) => {
      const option = pnTokenMask.split(":")[0].replace('{', '');
      const mask = pnTokenMask.split(":")[1].replace('}', '');

      console.log('option', option);
      console.log('mask', mask);
      console.log('partGroup', partGroup);

      if (searchArr.length > index) {

        if (option == 'Part Number') {
          // Skip
        }
        else if (option == 'Material') {
          const material = this.materialOptions.find(material => material.value == searchArr[index]);

          if (material) {
            newMaterial = material.value;
          }
        }
        else if (option == 'Plating') {
          const plating = this.platingOptions(newMaterial).find(plating => plating.value == searchArr[index]);

          if (plating) {
            newPlating = plating.value;
          }
        }
        else {
          const optionSet = partGroup.optionSets.find(optionSet => optionSet.name == option);

          if (optionSet) {
            const optionMatch = optionSet.options.find(option => option.value == searchArr[index]);

            if (optionMatch) {
              newOptions[option] = optionMatch.value;
            }
          }


          console.log('Selected option set', optionSet);
        }

      }

      index++;
    });

    console.log('Part Group', partGroup);
    console.log('partOptions', partOptions);

    if (newPlating.length === 0 && (newPartNumber && newMaterial
      && newPartNumber.length && newMaterial.length))
    newPlating = '0';

    const newState = {
      part: newPartNumber,
      partOptions: partOptions,
      options: newOptions,
      material: newMaterial,
      plating: newPlating
    };

    shop.inventory.getFilteredInventory(this.computedPartNumberFromData({ ...this.state, ...newState}, true));
    this.setState(newState);
  }

  componentWillReceiveProps(nextProps) {
    this.setOptionsFromSearch(nextProps);
  }

  componentWillMount() {
    this.setOptionsFromSearch(this.props);
  }

  renderInventory() {
    const { shop } = this.props
    const result = [];

    shop.inventory.filteredInventory.forEach(item => {

      const quantities = [];

      if (item.locationQuantities.length > 0) {
        item.locationQuantities.forEach(quantity => {
          quantities.push(
            <div>
              <span className="qtyLocation">{ quantity.location }: </span>
              <span className="qtyAmount">{ quantity.quantity }</span>
            </div>
          );
        });
      }
      else
        quantities.push(<div>{ item.quantity }</div>);

      result.push(
        <Table.Row positive={item.exactMatch} key={item.partNumber}>
          <Table.Cell>{ item.exactMatch && <Label color='teal' ribbon>Match</Label>}{ item.partNumber }</Table.Cell>
          <Table.Cell>{ quantities }</Table.Cell>
          <Table.Cell width='two'><Button fluid color='green' onClick={() => this.handleAddInventoryToQuote(item) }>Add to Quote</Button></Table.Cell>
        </Table.Row>
      );
    })

    return result;
  }

  render() {
    const { part, thread, material, plating, options , partOptionAttributes} = this.state
    const { shop, partGroup, selectedPartNumberCross } = this.props
    const sortedOptionSets = this.sortOptionSets(partGroup);
    const drawingForPartOptions = this.drawingForPartOptions;

    console.log('state', this.state);

    const optionSets = sortedOptionSets.map((optionSet) => {
        return this.renderOptionSet(optionSet);
      })

    const partOptions = partGroup.partOptions.map((option) => {
        return this.renderPartOption(option);
      })

    return(
      <div>
        {selectedPartNumberCross && 
        <MetaTags>
          <meta name="description" content={selectedPartNumberCross + ' Cross Reference for ' + partGroup.name} />
        </MetaTags>
        }
        {!selectedPartNumberCross && 
        <MetaTags>
          <meta name="description" content={partGroup.name} />
        </MetaTags>
        }
        <Container>

          {selectedPartNumberCross &&
          <Header as='h2' textAlign='center'>
            <Header.Content>{selectedPartNumberCross} Cross Reference</Header.Content>
          </Header>
          }
          <Header as='h2' textAlign='center'>
            <Header.Content>{partGroup.name}</Header.Content>
          </Header>
          <Image src={ '/images/partGroups/' + partGroup.imgSrc} alt={partGroup.name + ' diagram'} centered={true} />
          <Header as='h3' textAlign='center'>
            <Header.Content>Part #: {this.computedPartNumber}</Header.Content>
          </Header>
          {this.renderPartOptionAttributes()}
          {this.renderOptionAttributes()}
          <Form>
            <div className="fields">
              {partOptions}
              {optionSets}
              <div className="four wide field">
                <label>Material</label>
                <Dropdown placeholder='Material' aria-label="Material"  selection value={material} options={this.materialOptions} onChange={this.handleMaterialChange} />
              </div>
              <div className="four wide field">
                <label>Plating</label>
                <Dropdown placeholder='Plating' aria-label="Plating" selection value={plating} options={this.platingOptions()} onChange={this.handlePlatingChange} />
              </div>
            </div>
            <div className="fields">
              <div className="three wide field">
              </div>
              {!partGroup.printOptions &&
              <div className="two wide field">
              </div>
              }
              <div className="three wide field">
                <Button style={{marginTop: '10px'}} fluid color='green' disabled={!this.canQuote} onClick={this.handleAddToQuote}>Add to Quote</Button>
              </div>
              <div className="three wide field">
                <Button style={{marginTop: '10px'}} fluid color='orange' disabled={!drawingForPartOptions} onClick={()=> window.open(drawingForPartOptions, "_blank")}>View Catalog</Button>
              </div>
              {partGroup.printOptions &&
              <div className="three wide field">
                <Button style={{marginTop: '10px'}} fluid color='blue' disabled={!this.canQuote} onClick={()=> window.open('/api/print/' + this.computedPartNumber, "_blank")}>Download Print</Button>
              </div>
               }
            </div>

            {shop.inventory.filteredInventory.length > 0 && <div className="inventory">
            <Header as='h2' textAlign='center'>
              <Header.Content>Available Inventory</Header.Content>
            </Header>
              <Table unstackable striped columns={3}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Part Number</Table.HeaderCell>
                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                    <Table.HeaderCell width='two'> </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { this.renderInventory() }
                </Table.Body>
              </Table>
            </div> }
          </Form>
          {this.renderPlatingVariants()}
        </Container>
        <ScrollToTopOnMount />
      </div>
    )
  }
}

export default inject("shop")(observer(PagePartGroup))