import { types, getParent, flow } from "mobx-state-tree"

export const NavLink = types
  .model('NavLink', {
    title: types.string,
    page: types.maybe(types.string),
    url: types.maybe(types.string)
  })

export const NavLinkGroup = types
  .model('NavLinkGroup', {
    title: types.string,
    navLinks: types.array(NavLink)
  })

export const SiteConfigStore = types
  .model("SiteConfigStore", {
    isLoading: true,
    version: types.maybe(types.number),
    domain: types.maybe(types.string),
    companyName: types.maybe(types.string),
    companyTagLine: types.maybe(types.string),
    quoteDistributorEmail: types.maybe(types.string),
    quoteDistributorBCCs: types.maybe(types.string),
    trackingCode: types.optional(types.array(types.string), []),
    assetDirectory: types.maybe(types.string),
    desktopNavLinks: types.optional(types.array(NavLink), []),
    mobileNavLinks: types.optional(types.array(NavLink), []),
    footerNavLinks: types.optional(types.array(NavLinkGroup), [])
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    }
  }))
  .actions(self => {

    let siteConfigLoadPromise;

    function markLoading(loading) {
      self.isLoading = loading
    }

    function updateSiteConfig(json) {
      console.log(json);

      self.version = json.version;
      self.domain = json.domain;
      self.siteTitle = json.siteTitle;
      self.companyName = json.companyName;
      self.companyTagLine = json.companyTagLine;
      self.trackingCode = json.trackingCode;
      self.trackingCodeHead = json.trackingCodeHead;
      self.assetDirectory = json.assetDirectory;

      if (json.hasOwnProperty('desktopNavLinks')) {
        json.desktopNavLinks.forEach(navLink => {
          self.desktopNavLinks.push(NavLink.create(navLink))
        });
      }
      if (json.hasOwnProperty('mobileNavLinks')) {
        json.mobileNavLinks.forEach(navLink => {
          self.mobileNavLinks.push(NavLink.create(navLink))
        });
      }

      if (json.hasOwnProperty('footerNavLinks')) {
        json.footerNavLinks.forEach(navLinkGroup => {
          self.footerNavLinks.push(NavLinkGroup.create(navLinkGroup))
        });
      }
    }

    const _loadSiteConfig = flow(function* loadSiteConfig() {
      try {
        const json = yield self.shop.fetch("/api/config");
        updateSiteConfig(json)
        markLoading(false)
      } catch (err) {
        console.error("Failed to load site config ", err)
      }
    })

    function loadSiteConfig() {
      if (siteConfigLoadPromise)
        return siteConfigLoadPromise;

      siteConfigLoadPromise = _loadSiteConfig();
      return siteConfigLoadPromise;
    }

    return {
      updateSiteConfig,
      loadSiteConfig
    }
  })

export default SiteConfigStore