import React, { Component } from 'react'
import {
  Container,
  Divider,
  Grid,
  Segment,
  Card
} from 'semantic-ui-react'
import ProductCard from './ProductCard'

const cardDataStandard = [
  {
    image: '/images/female_standoffs-2.jpg',
    name: 'Female Standoffs',
    types: 'Hex - Round',
    materials: 'Aluminum - Brass - Steel - SS - Nylon',
    sizes: '1/8 through 5/8',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'female-standoff-hex-1-8' },
          { type: 'partGroup', name: '5/32', urlName: 'female-standoff-hex-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'female-standoff-hex-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'female-standoff-hex-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'female-standoff-hex-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'female-standoff-hex-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'female-standoff-hex-1-2' },
          { type: 'partGroup', name: '5/8', urlName: 'female-standoff-hex-5-8' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'female-standoff-round-1-8' },
          { type: 'partGroup', name: '5/32', urlName: 'female-standoff-round-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'female-standoff-round-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'female-standoff-round-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'female-standoff-round-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'female-standoff-round-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'female-standoff-round-1-2' },
          { type: 'partGroup', name: '5/8', urlName: 'female-standoff-round-5-8' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '1/8', file: 'female standoff hex 1-8.pdf' },
          { type: 'print', name: '3/16', file: 'female standoff hex 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'female standoff hex 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'female standoff hex 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'female standoff hex 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'female standoff hex 1-2.pdf' },
          { type: 'print', name: '5/8', file: 'female standoff hex 5-8.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '1/8', file: 'female standoff round 1-8.pdf' },
          { type: 'print', name: '3/16', file: 'female standoff round 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'female standoff round 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'female standoff round 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'female standoff round 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'female standoff round 1-2.pdf' },
          { type: 'print', name: '5/8', file: 'female standoff round 5-8.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/male-female_standoffs-2.jpg',
    name: 'Male-Female Standoffs',
    types: 'Hex - Round',
    materials: 'Aluminum - Brass - Steel - SS - Nylon',
    sizes: '3/16 through 1/2',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '5/32', urlName: 'mf-standoff-hex-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'mf-standoff-hex-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'mf-standoff-hex-1-4'},
          { type: 'partGroup', name: '5/16', urlName: 'mf-standoff-hex-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'mf-standoff-hex-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'mf-standoff-hex-1-2' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '5/32', urlName: 'mf-standoff-round-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'mf-standoff-round-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'mf-standoff-round-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'mf-standoff-round-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'mf-standoff-round-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'mf-standoff-round-1-2' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '3/16', file: 'mf standoff hex 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'mf standoff hex 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'mf standoff hex 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'mf standoff hex 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'mf standoff hex 1-2.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '3/16', file: 'mf standoff round 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'mf standoff round 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'mf standoff round 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'mf standoff round 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'mf standoff round 1-2.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/male-male_standoffs-2.jpg',
    name: 'Male-Male Standoffs',
    types: 'Hex',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '',
    partGroups: [
      { type: 'partGroup', name: 'Hex', urlName: 'male-male-standoff-hex' },
    ],
    prints: [
      { type: 'print', name: 'Hex', file: 'male-male standoff hex.pdf' },
    ]
  }, {
    image: '/images/sq-female.jpg',
    name: 'Square Female Standoffs',
    types: '',
    materials: 'Aluminum - Brass - Steel - SS - Nylon',
    sizes: '1/8 through 5/8',
    partGroups: [
      {
        type: 'group',
        name: 'Square',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'square-female-standoff-1-8' },
          { type: 'partGroup', name: '3/16', urlName: 'square-female-standoff-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'square-female-standoff-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'square-female-standoff-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'square-female-standoff-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'square-female-standoff-1-2' },
          { type: 'partGroup', name: '5/8', urlName: 'square-female-standoff-5-8' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Square',
        prints: [
          { type: 'print', name: '1/8', file: 'square female standoff 1-8.pdf' },
          { type: 'print', name: '3/16', file: 'square female standoff 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'square female standoff 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'square female standoff 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'square female standoff 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'square female standoff 1-2.pdf' },
          { type: 'print', name: '5/8', file: 'square female standoff 5-8.pdf' },
        ]
      },
    ]
  },


  {
    image: '/images/swages-2.jpg',
    name: 'Swage Standoffs',
    types: 'Hex - Round',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '1/8 through 3/8',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'swage-standoff-hex-1-8' },
          { type: 'partGroup', name: '5/32', urlName: 'swage-standoff-hex-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'swage-standoff-hex-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'swage-standoff-hex-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'swage-standoff-hex-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'swage-standoff-hex-3-8' },
          { type: 'partGroup', name: '3/16 .125 dia. shank', urlName: 'swage-standoff-hex-3-16-125' },
          { type: 'partGroup', name: '1/4 .136 dia. shank', urlName: 'swage-standoff-hex-1-4-136' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'swage-standoff-round-1-8' },
          { type: 'partGroup', name: '5/32', urlName: 'swage-standoff-round-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'swage-standoff-round-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'swage-standoff-round-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'swage-standoff-round-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'swage-standoff-round-3-8' },
          { type: 'partGroup', name: '3/16 .125 dia. shank', urlName: 'swage-standoff-round-3-16-125' },
          { type: 'partGroup', name: '1/4 .136 dia. shank', urlName: 'swage-standoff-round-1-4-136' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '1/8', file: 'swage standoff hex 1-8.pdf' },
          { type: 'print', name: '5/32', file: 'swage standoff hex 5-32.pdf' },
          { type: 'print', name: '3/16', file: 'swage standoff hex 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'swage standoff hex 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'swage standoff hex 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'swage standoff hex 3-8.pdf' },
          { type: 'print', name: '3/16 .125 dia. shank', file: 'swage standoff hex 3-16-125.pdf' },
          { type: 'print', name: '1/4 .136 dia. shank', file: 'swage standoff hex 1-4-136.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '1/8', file: 'swage standoff round 1-8.pdf' },
          { type: 'print', name: '5/32', file: 'swage standoff round 5-32.pdf' },
          { type: 'print', name: '3/16', file: 'swage standoff round 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'swage standoff round 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'swage standoff round 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'swage standoff round 3-8.pdf' },
          { type: 'print', name: '3/16 .125 dia. shank', file: 'swage standoff round 3-16-125.pdf' },
          { type: 'print', name: '1/4 .136 dia. shank', file: 'swage standoff round 1-4-136.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/spacers-2.jpg',
    name: 'Spacers',
    types: 'Hex - Round',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '1/8 through 3/4',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'spacers-hex-1-8' },
          { type: 'partGroup', name: '3/16', urlName: 'spacers-hex-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'spacers-hex-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'spacers-hex-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'spacers-hex-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'spacers-hex-1-2' },
          { type: 'partGroup', name: '5/8', urlName: 'spacers-hex-5-8' },
          { type: 'partGroup', name: '3/4', urlName: 'spacers-hex-3-4' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'spacers-round-1-8' },
          { type: 'partGroup', name: '3/16', urlName: 'spacers-round-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'spacers-round-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'spacers-round-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'spacers-round-3-8' },
          { type: 'partGroup', name: '1/2', urlName: 'spacers-round-1-2' },
          { type: 'partGroup', name: '5/8', urlName: 'spacers-round-5-8' },
          { type: 'partGroup', name: '3/4', urlName: 'spacers-round-3-4' },
          { type: 'partGroup', name: '1', urlName: 'spacers-round-1' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '1/8', file: 'spacers hex 1-8.pdf' },
          { type: 'print', name: '3/16', file: 'spacers hex 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'spacers hex 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'spacers hex 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'spacers hex 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'spacers hex 1-2.pdf' },
          { type: 'print', name: '5/8', file: 'spacers hex 5-8.pdf' },
          { type: 'print', name: '3/4', file: 'spacers hex 3-4.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '1/8', file: 'spacers round 1-8.pdf' },
          { type: 'print', name: '3/16', file: 'spacers round 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'spacers round 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'spacers round 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'spacers round 3-8.pdf' },
          { type: 'print', name: '1/2', file: 'spacers round 1-2.pdf' },
          { type: 'print', name: '5/8', file: 'spacers round 5-8.pdf' },
          { type: 'print', name: '3/4', file: 'spacers round 3-4.pdf' },
          { type: 'print', name: '1', file: 'spacers round 1.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/swages-2.jpg',
    name: 'Swage Spacers',
    types: 'Hex',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '3/16 and 1/4',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '3/16', urlName: 'swage-spacer-hex-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'swage-spacer-hex-1-4' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '3/16', urlName: 'swage-spacer-round-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'swage-spacer-round-1-4' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '3/16', file: 'swage spacer hex 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'swage spacer hex 1-4.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '3/16', file: 'swage spacer round 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'swage spacer round 1-4.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/jackscrews-2.jpg',
    name: 'Hex Jackscrews',
    types: 'Hex',
    materials: 'Brass - Steel - SS',
    sizes: '3/16 to 1/4',
    partGroups: [
      { type: 'partGroup', name: 'Hex', urlName: 'jackscrews-3-16-hex' },
    ],
    prints: [
      { type: 'print', name: 'Hex', file: 'jackscrews 3-16 hex.pdf' },
    ]
  },

  {
    image: '/images/thumbscrews-2.jpg',
    name: 'Thumbscrews',
    types: 'Washer Face - Push Type - Plain',
    types2: 'Nuts (Hex & Round)',
    materials: 'Aluminum - Brass - Steel - SS',
    partGroups: [
      { type: 'partGroup', name: 'Washer Face Screw', urlName: 'thumb-screws-washer-face' },
      { type: 'partGroup', name: 'Push Type Screw', urlName: 'thumb-screws-push-type' },
      { type: 'partGroup', name: 'Plain Screw', urlName: 'thumb-screws-plain' },
      { type: 'partGroup', name: 'Round Thumb Nut', urlName: 'thumb-round-nut' },
      { type: 'partGroup', name: 'Hex Thumb Nut', urlName: 'thumb-hex-nut' },
    ],
    prints: [
      { type: 'print', name: 'Washer Face Screw', file: 'thumb screws washer face.pdf' },
      { type: 'print', name: 'Push Type Screw', file: 'thumb screws push type.pdf' },
      { type: 'print', name: 'Plain Screw', file: 'thumb screws plain.pdf' },
      { type: 'print', name: 'Round Thumb Nut', file: 'thumb round nut.pdf' },
      { type: 'print', name: 'Hex Thumb Nut', file: 'thumb hex nut.pdf' },
    ]
  }, {
    image: '/images/retainers-2.jpg',
    name: 'Retainers',
    types: 'Type A - Type B - Type C - Type D',
    types2: 'Type E - Type F - Type G',
    materials: 'Brass - Steel - SS',
    partGroups: [
      { type: 'partGroup', name: 'Type A', urlName: 'retainer-A' },
      { type: 'partGroup', name: 'Type B', urlName: 'retainer-B' },
      { type: 'partGroup', name: 'Type C', urlName: 'retainer-C' },
      { type: 'partGroup', name: 'Type D', urlName: 'retainer-D' },
      { type: 'partGroup', name: 'Type E', urlName: 'retainer-E' },
      { type: 'partGroup', name: 'Type F', urlName: 'retainer-F' },
      { type: 'partGroup', name: 'Type G', urlName: 'retainer-G' },
    ],
    prints: [
      { type: 'print', name: 'Type A', file: 'retainer A.pdf' },
      { type: 'print', name: 'Type B', file: 'retainer B.pdf' },
      { type: 'print', name: 'Type C', file: 'retainer C.pdf' },
      { type: 'print', name: 'Type D', file: 'retainer D.pdf' },
      { type: 'print', name: 'Type E', file: 'retainer E.pdf' },
      { type: 'print', name: 'Type F', file: 'retainer F.pdf' },
      { type: 'print', name: 'Type G', file: 'retainer G.pdf' },
    ]
  }, {
    image: '/images/captive_screws-2.jpg',
    name: 'Captive Screws',
    types: 'Type 1 - Type 2 - Type 3',
    types2: 'Type 4 - Type 5 - Type 6',
    materials: 'Brass - Steel - SS',
    partGroups: [
      {
        type: 'group',
        name: 'Type 1',
        partGroups: [
          { type: 'partGroup', name: '4-40', urlName: 'captive-type-1-440' },
          { type: 'partGroup', name: '6-32', urlName: 'captive-type-1-632' },
          { type: 'partGroup', name: '8-32', urlName: 'captive-type-1-832' },
          { type: 'partGroup', name: '10-24', urlName: 'captive-type-1-1024' },
          { type: 'partGroup', name: '10-32', urlName: 'captive-type-1-1032' },
          { type: 'partGroup', name: '12-24', urlName: 'captive-type-1-1224' },
          { type: 'partGroup', name: '1/4-20', urlName: 'captive-type-1-1420' },
          { type: 'partGroup', name: '1/4-28', urlName: 'captive-type-1-1428' },
          { type: 'partGroup', name: '5/16-18', urlName: 'captive-type-1-51618' },
          { type: 'partGroup', name: '5/16-24', urlName: 'captive-type-1-51624' },
        ]
      },
      {
        type: 'group',
        name: 'Type 2',
        partGroups: [
          { type: 'partGroup', name: '4-40', urlName: 'captive-type-2-440' },
          { type: 'partGroup', name: '6-32', urlName: 'captive-type-2-632' },
          { type: 'partGroup', name: '8-32', urlName: 'captive-type-2-832' },
          { type: 'partGroup', name: '10-24', urlName: 'captive-type-2-1024' },
          { type: 'partGroup', name: '10-32', urlName: 'captive-type-2-1032' },
          { type: 'partGroup', name: '1/4-20', urlName: 'captive-type-2-1420' },
          { type: 'partGroup', name: '1/4-28', urlName: 'captive-type-2-1428' },
          { type: 'partGroup', name: '5/16-18', urlName: 'captive-type-2-51618' },
          { type: 'partGroup', name: '5/16-24', urlName: 'captive-type-2-51624' },
          { type: 'partGroup', name: '3/8-16', urlName: 'captive-type-2-3816' },
        ]
      },
      {
        type: 'group',
        name: 'Type 3',
        partGroups: [
          { type: 'partGroup', name: '4-40', urlName: 'captive-type-3-440' },
          { type: 'partGroup', name: '6-32', urlName: 'captive-type-3-632' },
          { type: 'partGroup', name: '8-32', urlName: 'captive-type-3-832' },
          { type: 'partGroup', name: '10-24', urlName: 'captive-type-3-1024' },
          { type: 'partGroup', name: '10-32', urlName: 'captive-type-3-1032' },
          { type: 'partGroup', name: '12-24', urlName: 'captive-type-3-1224' },
          { type: 'partGroup', name: '1/4-20 5/8 H.D.', urlName: 'captive-type-3-1420' },
          { type: 'partGroup', name: '1/4-20 3/4 H.D.', urlName: 'captive-type-3-1420B' },
          { type: 'partGroup', name: '1/4-28 5/8 H.D.', urlName: 'captive-type-3-1428' },
          { type: 'partGroup', name: '1/4-28 3/4 H.D.', urlName: 'captive-type-3-1428B' },
        ]
      },
      {
        type: 'group',
        name: 'Type 4',
        partGroups: [
          { type: 'partGroup', name: '2-56', urlName: 'captive-type-4-256' },
          { type: 'partGroup', name: '3-48', urlName: 'captive-type-4-348' },
          { type: 'partGroup', name: '4-40', urlName: 'captive-type-4-440' },
          { type: 'partGroup', name: '6-32', urlName: 'captive-type-4-632' },
          { type: 'partGroup', name: '8-32', urlName: 'captive-type-4-832' },
          { type: 'partGroup', name: '10-24', urlName: 'captive-type-4-1024' },
          { type: 'partGroup', name: '10-32', urlName: 'captive-type-4-1032' },
        ]
      },
      {
        type: 'group',
        name: 'Type 5',
        partGroups: [
          { type: 'partGroup', name: '4-40', urlName: 'captive-type-5-440' },
          { type: 'partGroup', name: '6-32', urlName: 'captive-type-5-632' },
          { type: 'partGroup', name: '8-32', urlName: 'captive-type-5-832' },
          { type: 'partGroup', name: '10-24', urlName: 'captive-type-5-1024' },
          { type: 'partGroup', name: '10-32', urlName: 'captive-type-5-1032' },
        ]
      },
      {
        type: 'group',
        name: 'Type 6',
        partGroups: [
          { type: 'partGroup', name: '4-40', urlName: 'captive-type-6' },
          { type: 'partGroup', name: '6-32', urlName: 'captive-type-6' },
          { type: 'partGroup', name: '8-32', urlName: 'captive-type-6' },
          { type: 'partGroup', name: '10-32', urlName: 'captive-type-6' },
        ]
      },
      {
        type: 'group',
        name: 'Washers',
        partGroups: [
          { type: 'partGroup', name: 'Type 5', urlName: 'captive-type-5-washers' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Type 1',
        prints: [
          { type: 'print', name: '4-40', file: 'captive type 1 440.pdf' },
          { type: 'print', name: '6-32', file: 'captive type 1 632.pdf' },
          { type: 'print', name: '8-32', file: 'captive type 1 832.pdf' },
          { type: 'print', name: '10-24', file: 'captive type 1 1024.pdf' },
          { type: 'print', name: '10-32', file: 'captive type 1 1032.pdf' },
          { type: 'print', name: '12-24', file: 'captive type 1 1224.pdf' },
          { type: 'print', name: '1/4-20', file: 'captive type 1 1420.pdf' },
          { type: 'print', name: '1/4-28', file: 'captive type 1 1428.pdf' },
          { type: 'print', name: '5/16-18', file: 'captive type 1 51618.pdf' },
          { type: 'print', name: '5/16-24', file: 'captive type 1 51624.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Type 2',
        prints: [
          { type: 'print', name: '4-40', file: 'captive type 3 440.pdf' },
          { type: 'print', name: '6-32', file: 'captive type 2 632.pdf' },
          { type: 'print', name: '8-32', file: 'captive type 2 832.pdf' },
          { type: 'print', name: '10-24', file: 'captive type 2 1024.pdf' },
          { type: 'print', name: '10-32', file: 'captive type 2 1032.pdf' },
          { type: 'print', name: '1/4-20', file: 'captive type 2 1420.pdf' },
          { type: 'print', name: '1/4-28', file: 'captive type 2 1428.pdf' },
          { type: 'print', name: '5/16-18', file: 'captive type 2 51618.pdf' },
          { type: 'print', name: '5/16-24', file: 'captive type 2 51624.pdf' },
          { type: 'print', name: '3/8-16', file: 'captive type 2 3816.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Type 3',
        prints: [
          { type: 'print', name: '4-40', file: 'captive type 3 440.pdf' },
          { type: 'print', name: '6-32', file: 'captive type 3 632.pdf' },
          { type: 'print', name: '8-32', file: 'captive type 3 832.pdf' },
          { type: 'print', name: '10-24', file: 'captive type 3 1024.pdf' },
          { type: 'print', name: '10-32', file: 'captive type 3 1032.pdf' },
          { type: 'print', name: '12-24', file: 'captive type 3 1224.pdf' },
          { type: 'print', name: '1/4-20 5/8 H.D.', file: 'captive type 3 1420.pdf' },
          { type: 'print', name: '1/4-20 3/4 H.D.', file: 'captive type 3 1420B.pdf' },
          { type: 'print', name: '1/4-28 5/8 H.D.', file: 'captive type 3 1428.pdf' },
          { type: 'print', name: '1/4-28 3/4 H.D.', file: 'captive type 3 1428B.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Type 4',
        prints: [
          { type: 'print', name: '2-56', file: 'captive type 4 256.pdf' },
          { type: 'print', name: '3-48', file: 'captive type 4 348.pdf' },
          { type: 'print', name: '4-40', file: 'captive type 4 440.pdf' },
          { type: 'print', name: '6-32', file: 'captive type 4 632.pdf' },
          { type: 'print', name: '8-32', file: 'captive type 4 832.pdf' },
          { type: 'print', name: '10-24', file: 'captive type 4 1024.pdf' },
          { type: 'print', name: '10-32', file: 'captive type 4 1032.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Type 5',
        prints: [
          { type: 'print', name: '4-40', file: 'captive type 5 440.pdf' },
          { type: 'print', name: '6-32', file: 'captive type 5 632.pdf' },
          { type: 'print', name: '8-32', file: 'captive type 5 832.pdf' },
          { type: 'print', name: '10-24', file: 'captive type 5 1024.pdf' },
          { type: 'print', name: '10-32', file: 'captive type 5 1032.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Type 6',
        prints: [
          { type: 'print', name: '4-40', file: 'captive type 6 440.pdf' },
          { type: 'print', name: '6-32', file: 'captive type 6 632.pdf' },
          { type: 'print', name: '8-32', file: 'captive type 6 832.pdf' },
          { type: 'print', name: '10-32', file: 'captive type 6 1032.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Washers',
        prints: [
          { type: 'print', name: 'Type 5', file: 'captive type 5 washers.pdf' },
        ]
      },
    ]
  },  {
    image: '/images/ferrules.jpg',
    name: 'Ferrules',
    types: 'Type 1 - Type 2 - Type 3',
    types2: 'Type 4 - Type 5',
    materials: 'Aluminum - Brass - Steel - SS',
    partGroups: [
      { type: 'partGroup', name: 'Type 1', urlName: 'ferrule-type-1' },
      { type: 'partGroup', name: 'Type 2', urlName: 'ferrule-type-2' },
      { type: 'partGroup', name: 'Type 3', urlName: 'ferrule-type-3' },
      { type: 'partGroup', name: 'Type 4', urlName: 'ferrule-type-4' },
      { type: 'partGroup', name: 'Type 5', urlName: 'ferrule-type-5' },
    ],
    prints: [
      { type: 'print', name: 'Type 1', file: 'ferrule type 1.pdf' },
      { type: 'print', name: 'Type 2', file: 'ferrule type 2.pdf' },
      { type: 'print', name: 'Type 3', file: 'ferrule type 3.pdf' },
      { type: 'print', name: 'Type 4', file: 'ferrule type 4.pdf' },
      { type: 'print', name: 'Type 5', file: 'ferrule type 5.pdf' },
    ]
  }, {
    image: '/images/shoulder_screws-2.jpg',
    name: 'Shoulder Screws',
    types: 'Socket Head - Slotted Head',
    materials: 'Brass - Steel - SS',
    partGroups: [
      { type: 'partGroup', name: 'Socket Head', urlName: 'socket-head-shoulder-screws' },
      { type: 'partGroup', name: 'Slotted Head', urlName: 'slotted-head-shoulder-screws' },
    ],
    prints: [
      { type: 'print', name: 'Socket Head', file: 'socket head shoulder screws.pdf' },
      { type: 'print', name: 'Slotted Head', file: 'slotted shoulder screws.pdf' },
    ]
  }, {
    image: '/images/handles-3.jpg',
    name: 'Handles',
    types: 'Internal Thread Handles',
    types2: 'Round - Round Off-Set - Oval',
    materials: 'Aluminum - Brass - SS',
    partGroups: [
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '1/8', urlName: 'handles-round-1-8' },
          { type: 'partGroup', name: '5/32', urlName: 'handles-round-5-32' },
          { type: 'partGroup', name: '3/16', urlName: 'handles-round-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'handles-round-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'handles-round-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'handles-round-3-8' },
        ]
      },
      {
        type: 'group',
        name: 'Round Off-Set',
        partGroups: [
          { type: 'partGroup', name: '3/16', urlName: 'handles-round-offset-3-16' },
          { type: 'partGroup', name: '1/4', urlName: 'handles-round-offset-1-4' },
          { type: 'partGroup', name: '5/16', urlName: 'handles-round-offset-5-16' },
          { type: 'partGroup', name: '3/8', urlName: 'handles-round-offset-3-8' },
        ]
      },
      {
        type: 'group',
        name: 'Oval',
        partGroups: [
          { type: 'partGroup', name: '7/16 x 9/32', urlName: 'handles-oval-7-16' },
          { type: 'partGroup', name: '5/8 x 9/32', urlName: 'handles-oval-5-8' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '1/8', file: 'handles round 1-8.pdf' },
          { type: 'print', name: '5/32', file: 'handles round 5-32.pdf' },
          { type: 'print', name: '3/16', file: 'handles round 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'handles round 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'handles round 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'handles round 3-8.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round Off-Set',
        prints: [
          { type: 'print', name: '3/16', file: 'handles round offset 3-16.pdf' },
          { type: 'print', name: '1/4', file: 'handles round offset 1-4.pdf' },
          { type: 'print', name: '5/16', file: 'handles round offset 5-16.pdf' },
          { type: 'print', name: '3/8', file: 'handles round offset 3-8.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Oval',
        prints: [
          { type: 'print', name: '7/16 x 9/32', file: 'handles oval 7-16.pdf' },
          { type: 'print', name: '5/8 x 9/32', file: 'handles oval 5-8.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/chassisfastener.jpg',
    name: 'Chassis Fasteners',
    materials: 'Aluminum - Brass',
    sizes: '4-40 Thread - 6-32 Thread',
    partGroups: [
      { type: 'partGroup', name: '4-40 Thread', urlName: 'chassis-fastener-4-40' },
      { type: 'partGroup', name: '6-32 Thread', urlName: 'chassis-fastener-6-32' },
    ],
    prints: [
      { type: 'print', name: '4-40 Thread', file: 'chassis fastener 4-40.pdf' },
      { type: 'print', name: '6-32 Thread', file: 'chassis fastener 6-32.pdf' },
    ]
  }, {
    image: '/images/ballstuds.jpg',
    name: 'Ball Studs',
    types: 'Male Thread, Female Thread',
    materials: 'Brass - Steel - SS',
    sizes: '3/16 to 1/4',
    partGroups: [
      { type: 'partGroup', name: 'Male Thread', urlName: 'ball-studs-male-thread' },
      { type: 'partGroup', name: 'Female Thread', urlName: 'ball-studs-female-thread' },
    ],
    prints: [
      { type: 'print', name: 'Male Thread', file: 'ball studs male thread.pdf' },
      { type: 'print', name: 'Female Thread', file: 'ball studs female thread.pdf' },
    ]
  }, {
    image: '/images/socketheadcaptivescrews.jpg',
    name: 'Socket Head Captive Screws',
    types: 'Socket Head Captive Screw',
    materials: 'Brass - Steel - SS',
    sizes: '3/16 to 1/4',
    partGroups: [
      { type: 'partGroup', name: 'Socket Head Captive Screw', urlName: 'socket-head-captive-screw' },
    ],
    prints: [
      { type: 'print', name: 'Socket Head Captive Screw', file: 'socket head captive screw.pdf' },
    ]
  }, {
    image: '/images/precisionpanelnuts.jpg',
    name: 'Precision',
    materials: 'Brass - Steel - SS',
    sizes: '4-40 Thread - 5/8-27 Thread',
    partGroups: [
      { type: 'partGroup', name: 'Precision Panel Nuts', urlName: 'precision-panel-nuts' },
    ],
    prints: [
      { type: 'print', name: 'Precision Panel Nuts', file: 'precision panel nuts.pdf' },
    ]
  }
]

const cardDataMetric = [
  {
    image: '/images/female_standoffs-2.jpg',
    name: 'Female Standoffs',
    types: 'Hex - Round',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '4.5mm through 13mm',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '4.5mm', urlName: 'metric-female-standoff-hex-45' },
          { type: 'partGroup', name: '5mm', urlName: 'metric-female-standoff-hex-5' },
          { type: 'partGroup', name: '5.5mm', urlName: 'metric-female-standoff-hex-55' },
          { type: 'partGroup', name: '6mm', urlName: 'metric-female-standoff-hex-6' },
          { type: 'partGroup', name: '7mm', urlName: 'metric-female-standoff-hex-7' },
          { type: 'partGroup', name: '8mm', urlName: 'metric-female-standoff-hex-8' },
          { type: 'partGroup', name: '10mm', urlName: 'metric-female-standoff-hex-10' },
          { type: 'partGroup', name: '13mm', urlName: 'metric-female-standoff-hex-13' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '4.5mm', urlName: 'metric-female-standoff-round-45' },
          { type: 'partGroup', name: '6mm', urlName: 'metric-female-standoff-round-6' },
          { type: 'partGroup', name: '8mm', urlName: 'metric-female-standoff-round-8' },
          { type: 'partGroup', name: '10mm', urlName: 'metric-female-standoff-round-10' },
          { type: 'partGroup', name: '13mm', urlName: 'metric-female-standoff-round-13' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '4.5mm', file: 'metric female Standoff Hex 45.pdf' },
          { type: 'print', name: '5mm', file: 'metric female Standoff Hex 5.pdf' },
          { type: 'print', name: '5.5mm', file: 'metric female Standoff Hex 55.pdf' },
          { type: 'print', name: '6mm', file: 'metric female Standoff Hex 6.pdf' },
          { type: 'print', name: '7mm', file: 'metric female Standoff Hex 7.pdf' },
          { type: 'print', name: '8mm', file: 'metric female Standoff Hex 8.pdf' },
          { type: 'print', name: '10mm', file: 'metric female Standoff Hex 10.pdf' },
          { type: 'print', name: '13mm', file: 'metric female Standoff Hex 13.pdf' },
        ]
      },
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '4.5mm', file: 'metric female Standoff round 45.pdf' },
          { type: 'print', name: '6mm', file: 'metric female Standoff round 6.pdf' },
          { type: 'print', name: '8mm', file: 'metric female Standoff round 8.pdf' },
          { type: 'print', name: '10mm', file: 'metric female Standoff round 10.pdf' },
          { type: 'print', name: '13mm', file: 'metric female Standoff round 13.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/male-female_standoffs-2.jpg',
    name: 'Male-Female Standoffs',
    types: 'Hex',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '4.5mm through 13mm',
    partGroups: [
      {
        type: 'group',
        name: 'Hex',
        partGroups: [
          { type: 'partGroup', name: '4.5mm', urlName: 'metric-mf-standoff-hex-45' },
          { type: 'partGroup', name: '5mm', urlName: 'metric-mf-standoff-hex-5' },
          { type: 'partGroup', name: '5.5mm', urlName: 'metric-mf-standoff-hex-55' },
          { type: 'partGroup', name: '6mm', urlName: 'metric-mf-standoff-hex-6' },
          { type: 'partGroup', name: '7mm', urlName: 'metric-mf-standoff-hex-7' },
          { type: 'partGroup', name: '8mm', urlName: 'metric-mf-standoff-hex-8' },
          { type: 'partGroup', name: '10mm', urlName: 'metric-mf-standoff-hex-10' },
          { type: 'partGroup', name: '13mm', urlName: 'metric-mf-standoff-hex-13' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Hex',
        prints: [
          { type: 'print', name: '4.5mm', file: 'metric MF Standoff Hex 45.pdf' },
          { type: 'print', name: '5mm', file: 'metric MF Standoff Hex 5.pdf' },
          { type: 'print', name: '5.5mm', file: 'metric MF Standoff Hex 55.pdf' },
          { type: 'print', name: '6mm', file: 'metric MF Standoff Hex 6.pdf' },
          { type: 'print', name: '7mm', file: 'metric MF Standoff Hex 7.pdf' },
          { type: 'print', name: '8mm', file: 'metric MF Standoff Hex 8.pdf' },
          { type: 'print', name: '10mm', file: 'metric MF Standoff Hex 10.pdf' },
          { type: 'print', name: '13mm', file: 'metric MF Standoff Hex 13.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/spacers-2.jpg',
    name: 'Spacers',
    types: 'Hex - Round',
    materials: 'Aluminum - Brass - Steel - SS',
    sizes: '4.5mm through 19mm',
    partGroups: [
      {
        type: 'group',
        name: 'Round',
        partGroups: [
          { type: 'partGroup', name: '4.5mm', urlName: 'metric-spacers-45' },
          { type: 'partGroup', name: '6mm', urlName: 'metric-spacers-6' },
          { type: 'partGroup', name: '8mm', urlName: 'metric-spacers-8' },
          { type: 'partGroup', name: '10mm', urlName: 'metric-spacers-10' },
          { type: 'partGroup', name: '13mm', urlName: 'metric-spacers-13' },
          { type: 'partGroup', name: '16mm', urlName: 'metric-spacers-16' },
          { type: 'partGroup', name: '19mm', urlName: 'metric-spacers-19' },
        ]
      },
    ],
    prints: [
      {
        type: 'group',
        name: 'Round',
        prints: [
          { type: 'print', name: '4.5mm', file: 'metric spacers 45.pdf' },
          { type: 'print', name: '6mm', file: 'metric spacers 6.pdf' },
          { type: 'print', name: '8mm', file: 'metric spacers 8.pdf' },
          { type: 'print', name: '10mm', file: 'metric spacers 10.pdf' },
          { type: 'print', name: '13mm', file: 'metric spacers 13.pdf' },
          { type: 'print', name: '16mm', file: 'metric spacers 16.pdf' },
          { type: 'print', name: '19mm', file: 'metric spacers 19.pdf' },
        ]
      },
    ]
  }, {
    image: '/images/socketheadcaptivescrews.jpg',
    name: 'Socket Head Captive Screws',
    types: 'Socket Head Captive Screw',
    materials: 'Brass - Steel - SS',
    sizes: '3/16 to 1/4',
    partGroups: [
      { type: 'partGroup', name: 'Socket Head Captive Screw', urlName: 'metric-socket-head-captive-screw' },
    ],
    prints: [
      { type: 'print', name: 'Socket Head Captive Screw', file: 'metric socket head captive screw.pdf' },
    ]
  },
]

export const cardData = [{
  name: 'American Standard Sizes',
  line: 'Standard',
  products: cardDataStandard,
}, {
  name: 'Metric Sizes',
  line: 'Metric',
  products: cardDataMetric
}]



const ProductCards = ({line, cards, showPrints, onClickPartGroup}) => (
    cards.map((card, idx) => (
      <ProductCard card={card} line={line} showPrints={showPrints} key={'productCard_' + idx} onClickPartGroup={onClickPartGroup} />
)))

const ProductCardLine = ({line, showPrints, onClickPartGroup}) => (
    <Container>
      <Divider horizontal style={{ padding: '2em 0em', fontSize: '1.3rem' }}>{line.name}</Divider>
      <Grid container stackable verticalAlign='middle'>

        <Grid.Row>
          <Grid.Column width={16}>
            <Card.Group centered={true} stackable={true}>
              <ProductCards cards={line.products} line={line.line} showPrints={showPrints} onClickPartGroup={onClickPartGroup} />
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
)

const ProductCardLines = ({lines, showPrints, onClickPartGroup}) => (
    <Segment style={{ padding: '0 0 4em 0' }} vertical>
      {lines.map((line, idx) => (
      <ProductCardLine line={line} showPrints={showPrints} key={'productCardLine_' + idx} onClickPartGroup={onClickPartGroup} />))}
    </Segment>
)

export default ProductCardLines