import 'react-app-polyfill/ie9';
import 'babel-polyfill';
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "mobx-react"
import { observable, reaction } from "mobx"
import {
    onSnapshot,
    onAction,
    onPatch,
    applySnapshot,
    applyAction,
    applyPatch,
    getSnapshot
} from "mobx-state-tree"

import createRouter from "./utils/router"
import App from "./components/App"
//import HomepageLayout from "./components/Home"
import 'semantic-ui-css/semantic.min.css';

import { ShopStore } from "./stores/ShopStore"

const fetcher = url => window.fetch(url).then(response => response.json())
const shop = ShopStore.create(
    {},
    {
        fetch: fetcher,
        alert: m => console.log(m) // Noop for demo: window.alert(m)
    }
)

const history = {
    snapshots: observable.shallowArray(),
    actions: observable.shallowArray(),
    patches: observable.shallowArray()
}

/**
 * Rendering
 */
ReactDOM.render(
    <Provider shop={shop} history={history}>
        <App />
    </Provider>,
    document.getElementById("root")
)

/**
 * Routing
 */

reaction(
    () => shop.view.currentUrl,
    path => {
        if (window.location.pathname !== path) window.history.pushState(null, null, path)
    }
)

const router = createRouter({



    "/partgroup/:urlName": ({ urlName }) => shop.view.openPartGroupPageByUrlName(urlName),
    "/part/:partNumber": ({ partNumber }) => shop.view.openPartGroupPageByPartNumber(partNumber),
    "/partcross/:partCross": ({ partCross }) => shop.view.openPartGroupPageByPartNumberCross(partCross),
    "/item/:itemId": ({ itemId }) => shop.view.openItemPageById(itemId),
    "/termsofsale": shop.view.openTermsOfSalePage,
    "/depthofthreadmf": shop.view.openDepthOfThreadMFPage,
    "/depthofthreadf": shop.view.openDepthOfThreadFPage,
    "/torquevalues": shop.view.openTorqueValuesPage,
    "/material": shop.view.openMaterialPage,
    "/plating": shop.view.openPlatingPage,
    "/createprint": shop.view.openCreatePrintPage,
    "/crossref": shop.view.openCrossRefPage,
    "/technical": shop.view.openTechnicalPage,
    "/catalog": shop.view.openCatalogPage,
    "/cart": shop.view.openCartPage,
    "/rfq": shop.view.openRFQPage,
    "/": shop.view.openCatalogPage,

    "/spr3-16.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-3-16"),
    "/jackscrew.html": () => shop.view.openPartGroupPageByUrlName("jackscrews-3-16-hex"),
    "/capw.html": () => shop.view.openPartGroupPageByUrlName("captive-type-5-washers"),
    "/hssp3-16.html": () => shop.view.openPartGroupPageByUrlName("swage-spacer-hex-3-16"),
    "/rssp3-16.html": () => shop.view.openPartGroupPageByUrlName("swage-spacer-round-3-16"),
    "/fstyle5.html": () => shop.view.openPartGroupPageByUrlName("ferrule-type-5"),
    "/fstyle6.html": () => shop.view.openPartGroupPageByUrlName("ferrule-type-6"),
    "/fsh1-8.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-1-8"),
    "/fsh5-32.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-5-32"),
    "/fsr1-8.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-1-8"),
    "/fsr5-32.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-5-32"),
    "/mfsh3-16.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-hex-3-16"),
    "/mfsh5-32.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-hex-5-32"),
    "/mfsr3-16.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-round-3-16"),
    "/mfsr5-32.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-round-5-32"),
    "/sph1-2.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-1-2"),
    "/sph1-4.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-1-4"),
    "/sph1-8.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-1-8"),
    "/sph3-4.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-3-4"),
    "/sph3-8.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-3-8"),
    "/sph3-16.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-3-16"),
    "/sph5-8.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-5-8"),
    "/sph5-16.html": () => shop.view.openPartGroupPageByUrlName("spacers-hex-5-16"),
    "/spr1.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-1"),
    "/spr1-2.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-1-2"),
    "/spr1-4.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-1-4"),
    "/spr1-8.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-1-8"),
    "/spr3-4.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-3-4"),
    "/spr3-8.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-3-8"),
    "/spr5-8.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-5-8"),
    "/spr5-16.html": () => shop.view.openPartGroupPageByUrlName("spacers-round-5-16"),
    "/fss1-8.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/male-male.html": () => shop.view.openPartGroupPageByUrlName("male-male-standoff-hex"),
    "/tswf.html": () => shop.view.openPartGroupPageByUrlName("thumb-screws-washer-face"),
    "/tnr.html": () => shop.view.openPartGroupPageByUrlName("thumb-round-nut"),
    "/tnh.html": () => shop.view.openPartGroupPageByUrlName("thumb-hex-nut"),
    "/capt1440.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-440"),
    "/capt1632.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-632"),
    "/capt4256.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-256"),
    "/capt4348.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-348"),
    "/capt6.html": () => shop.view.openPartGroupPageByUrlName("captive-type-6"),
    "/ssh1-8.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-1-8"),
    "/ssh5-32.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-5-32"),
    "/ssh3-16.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-3-16"),
    "/ssh1-4.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-1-4"),
    "/ssh5-16.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-5-16"),
    "/ssh3-8.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-3-8"),
    "/ssh3-16-125.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-3-16-125"),
    "/ssh1-4-136.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-hex-1-4-136"),
    "/ssr1-8.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-1-8"),
    "/ssr5-32.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-5-32"),
    "/ssr3-16.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-3-16"),
    "/hssp1-4.html": () => shop.view.openPartGroupPageByUrlName("swage-spacer-hex-1-4"),
    "/rssp1-4.html": () => shop.view.openPartGroupPageByUrlName("swage-spacer-round-1-4"),
    "/cf440.html": () => shop.view.openPartGroupPageByUrlName("chassis-fastener-4-40"),
    "/cf632.html": () => shop.view.openPartGroupPageByUrlName("chassis-fastener-6-32"),
    "/roh-5-16.html": () => shop.view.openPartGroupPageByUrlName("handles-round-offset-5-16"),
    "/roh3-8.html": () => shop.view.openPartGroupPageByUrlName("handles-round-offset-3-8"),
    "/fstyle1.html": () => shop.view.openPartGroupPageByUrlName("ferrule-type-1"),
    "/fstyle3.html": () => shop.view.openPartGroupPageByUrlName("ferrule-type-3"),
    "/fstyle4.html": () => shop.view.openPartGroupPageByUrlName("ferrule-type-4"),
    "/fsh1-2.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-1-2"),
    "/fsh1-4.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-1-4"),
    "/fsh3-8.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-3-8"),
    "/fsh3-16.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-3-16"),
    "/fsh5-16.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-hex-5-16"),
    "/fsr1-2.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-1-2"),
    "/fsr1-4.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-1-4"),
    "/fsr3-8.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-3-8"),
    "/fsr3-16.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-3-16"),
    "/fsr5-16.html": () => shop.view.openPartGroupPageByUrlName("female-standoff-round-5-16"),
    "/mfsh1-2.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-hex-1-2"),
    "/mfsh1-4.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-hex-1-4"),
    "/mfsh3-8.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-hex-3-8"),
    "/mfsh5-16.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-hex-5-16"),
    "/mfsr1-2.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-round-1-2"),
    "/mfsr1-4.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-round-1-4"),
    "/mfsr3-8.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-round-3-8"),
    "/mfsr5-16.html": () => shop.view.openPartGroupPageByUrlName("mf-standoff-round-5-16"),
    "/fss3-16.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/fss1-4.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/fss5-16.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/fss3-8.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/fss1-2.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/fss5-8.html": () => shop.view.openPartGroupPageByUrlName("square-female-standoff-1-8"),
    "/tspt.html": () => shop.view.openPartGroupPageByUrlName("thumb-screws-push-type"),
    "/tspl.html": () => shop.view.openPartGroupPageByUrlName("thumb-screws-plain"),
    "/capt1832.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-832"),
    "/capt11024.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-1024"),
    "/capt11032.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-1032"),
    "/capt11224.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-1224"),
    "/capt11420.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-1420"),
    "/capt11428.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-1428"),
    "/capt151618.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-51618"),
    "/capt151624.html": () => shop.view.openPartGroupPageByUrlName("captive-type-1-51624"),
    "/capt2440.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-440"),
    "/capt2632.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-632"),
    "/capt2832.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-832"),
    "/capt21024.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-1024"),
    "/capt21032.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-1032"),
    "/capt21420.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-1420"),
    "/capt21428.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-1428"),
    "/capt251618.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-51618"),
    "/capt251624.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-51624"),
    "/capt23816.html": () => shop.view.openPartGroupPageByUrlName("captive-type-2-3816"),
    "/capt3440.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-440"),
    "/capt3632.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-632"),
    "/capt3832.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-832"),
    "/capt31024.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-1024"),
    "/capt31032.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-1032"),
    "/capt31420.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-1420"),
    "/capt31428.html": () => shop.view.openPartGroupPageByUrlName("captive-type-3-1428"),
    "/capt4440.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-440"),
    "/capt4632.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-632"),
    "/capt4832.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-832"),
    "/capt41024.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-1024"),
    "/capt41032.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-1032"),
    "/capt5440.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-440"),
    "/capt5632.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-632"),
    "/capt5832.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-832"),
    "/capt51024.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-1024"),
    "/capt51032.html": () => shop.view.openPartGroupPageByUrlName("captive-type-4-1032"),
    "/ssocket.html": () => shop.view.openPartGroupPageByUrlName("socket-head-shoulder-screws"),
    "/sslotted.html": () => shop.view.openPartGroupPageByUrlName("slotted-head-shoulder-screws"),
    "/ssr1-4.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-1-4"),
    "/ssr5-16.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-5-16"),
    "/ssr3-8.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-3-8"),
    "/ssr3-16-125.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-3-16-125"),
    "/ssr1-4-136.html": () => shop.view.openPartGroupPageByUrlName("swage-standoff-round-1-4-136"),
    "/retainera.html": () => shop.view.openPartGroupPageByUrlName("retainer-A"),
    "/retainerb.html": () => shop.view.openPartGroupPageByUrlName("retainer-B"),
    "/retainerc.html": () => shop.view.openPartGroupPageByUrlName("retainer-C"),
    "/retainerd.html": () => shop.view.openPartGroupPageByUrlName("retainer-D"),
    "/retainere.html": () => shop.view.openPartGroupPageByUrlName("retainer-E"),
    "/retainerf.html": () => shop.view.openPartGroupPageByUrlName("retainer-F"),
    "/retainerg.html": () => shop.view.openPartGroupPageByUrlName("retainer-G"),
    "/rh1-8.html": () => shop.view.openPartGroupPageByUrlName("handles-round-1-8"),
    "/rh5-32.html": () => shop.view.openPartGroupPageByUrlName("handles-round-5-32"),
    "/rh3-16.html": () => shop.view.openPartGroupPageByUrlName("handles-round-3-16"),
    "/rh1-4.html": () => shop.view.openPartGroupPageByUrlName("handles-round-1-4"),
    "/rh5-16.html": () => shop.view.openPartGroupPageByUrlName("handles-round-5-16"),
    "/rh3-8.html": () => shop.view.openPartGroupPageByUrlName("handles-round-3-8"),
    "/roh3-16.html": () => shop.view.openPartGroupPageByUrlName("handles-round-offset-3-16"),
    "/roh1-4.html": () => shop.view.openPartGroupPageByUrlName("handles-round-offset-1-4"),
    "/oh7-16.html": () => shop.view.openPartGroupPageByUrlName("handles-oval-7-16"),
    "/oh-5-8.html": () => shop.view.openPartGroupPageByUrlName("handles-oval-5-8"),
    "/fstyle2.html": () => shop.view.openPartGroupPageByUrlName("ferrule-type-2"),
    "/metmf5.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-5"),
    "/metmf6.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-6"),
    "/metmf7.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-7"),
    "/metmf8.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-8"),
    "/metmf10.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-10"),
    "/metmf13.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-13"),
    "/metmf45.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-45"),
    "/metmf55.html": () => shop.view.openPartGroupPageByUrlName("metric-mf-standoff-hex-55"),
    "/metsp6.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-6"),
    "/metsp8.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-8"),
    "/metsp10.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-10"),
    "/metsp13.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-13"),
    "/metsp16.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-16"),
    "/metsp19.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-19"),
    "/metsp45.html": () => shop.view.openPartGroupPageByUrlName("metric-spacers-45"),
    "/metfh45.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-45"),
    "/metfh5.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-5"),
    "/metfh55.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-55"),
    "/metfh6.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-6"),
    "/metfh7.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-7"),
    "/metfh8.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-8"),
    "/metfh10.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-10"),
    "/metfh13.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-hex-13"),
    "/metfr45.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-round-45"),
    "/metfr6.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-round-6"),
    "/metfr8.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-round-8"),
    "/metfr10.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-round-10"),
    "/metfr13.html": () => shop.view.openPartGroupPageByUrlName("metric-female-standoff-round-13"), 
  
  
    "/terms.html": shop.view.openTermsOfSalePage,
    "/mf_depths.html": shop.view.openDepthOfThreadMFPage,
    "/depth.html": shop.view.openDepthOfThreadFPage,
    "/material.html": shop.view.openMaterialPage,
    "/plating.html": shop.view.openPlatingPage,
    "/drawings.html": shop.view.openCreatePrintPage,
    "/crossquote": shop.view.openCrossRefPage,
    "/techinfo.html": shop.view.openTechnicalPage,
    "/catalog2.html": shop.view.openCatalogPage,
    "/american2.html": shop.view.openCatalogPage,
    "/catalog.html": shop.view.openCatalogPage,
    "/american.html": shop.view.openCatalogPage,
    "/metric.html": shop.view.openCatalogPage,
})

window.onpopstate = function historyChange(ev) {
    if (ev.type === "popstate") router(window.location.pathname)
}

router(window.location.pathname)

// ---------------

window.shop = shop // for playing around with the console

/**
 * Poor man's effort of "DevTools" to demonstrate the api:
 */

let recording = true // supress recording history when replaying

onSnapshot(
    shop,
    s =>
        recording &&
        history.snapshots.unshift({
            data: s,
            replay() {
                recording = false
                applySnapshot(shop, this.data)
                recording = true
            }
        })
)
onPatch(
    shop,
    s =>
        recording &&
        history.patches.unshift({
            data: s,
            replay() {
                recording = false
                applyPatch(shop, this.data)
                recording = true
            }
        })
)
onAction(
    shop,
    s =>
        recording &&
        history.actions.unshift({
            data: s,
            replay() {
                recording = false
                applyAction(shop, this.data)
                recording = true
            }
        })
)

// add initial snapshot
history.snapshots.push({
    data: getSnapshot(shop),
    replay() {
        // TODO: DRY
        recording = false
        applySnapshot(shop, this.data)
        recording = true
    }
})