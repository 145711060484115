import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Table,
  Icon,
  Header,
  Label
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'

const PageDepthOfThreadF = () => (
  <div>
    <Container>
      <Header as='h2' dividing textAlign='center'>
        <Icon name='info circle' />
        <Header.Content>Tap Depths for Female Standoffs</Header.Content>
      </Header>

      <Table celled unstackable columns={4}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="4">American Thread</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="2">Tapped Thru</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Longer Parts</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Thread</Table.HeaderCell>
            <Table.HeaderCell>Aluminum, Brass, Steel &amp; Nylon</Table.HeaderCell>
            <Table.HeaderCell>Stainless Steel</Table.HeaderCell>
            <Table.HeaderCell>Blind Tap Depth</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>0-80</Table.Cell>
            <Table.Cell>3/8</Table.Cell>
            <Table.Cell>1/4</Table.Cell>
            <Table.Cell>3/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1-72</Table.Cell>
            <Table.Cell>3/8</Table.Cell>
            <Table.Cell>1/4</Table.Cell>
            <Table.Cell>3/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>2-56</Table.Cell>
            <Table.Cell>3/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
            <Table.Cell>3/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3-48</Table.Cell>
            <Table.Cell>3/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
            <Table.Cell>3/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3-56</Table.Cell>
            <Table.Cell>3/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
            <Table.Cell>3/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>4-40</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>3/4</Table.Cell>
            <Table.Cell>1/4</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>4-48</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>3/4</Table.Cell>
            <Table.Cell>1/4</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5-40</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>3/4</Table.Cell>
            <Table.Cell>5/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>6-32</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>3/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>6-40</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>3/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>8-32</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>7/16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10-24</Table.Cell>
            <Table.Cell>1-1/8</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>1/2</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>10-32</Table.Cell>
            <Table.Cell>1-1/8</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>1/2</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>12-24</Table.Cell>
            <Table.Cell>1-1/8</Table.Cell>
            <Table.Cell>1</Table.Cell>
            <Table.Cell>1/2</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/4-20</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/4-28</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5/16-18</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5/16-24</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3/8-16</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3/8-24</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>7/16-14</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>7/16-20</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/2-13</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>1/2-20</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>1-1/4</Table.Cell>
            <Table.Cell>5/8</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Table celled unstackable columns={4}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" colSpan="4">Metrics Thread</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" colSpan="2">Tapped Thru</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Longer Parts</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Thread</Table.HeaderCell>
            <Table.HeaderCell>Aluminum, Brass, Steel &amp; Nylon</Table.HeaderCell>
            <Table.HeaderCell>Stainless Steel</Table.HeaderCell>
            <Table.HeaderCell>Blind Tap Depth</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>2.5x0.45</Table.Cell>
            <Table.Cell>19</Table.Cell>
            <Table.Cell>16</Table.Cell>
            <Table.Cell>4.7</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3x0.5</Table.Cell>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>19</Table.Cell>
            <Table.Cell>6.3</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>3.5x0.6</Table.Cell>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>9.5</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>4x0.7</Table.Cell>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>11.1</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>5x0.8</Table.Cell>
            <Table.Cell>28</Table.Cell>
            <Table.Cell>25</Table.Cell>
            <Table.Cell>12.7</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>6x1</Table.Cell>
            <Table.Cell>31</Table.Cell>
            <Table.Cell>31</Table.Cell>
            <Table.Cell>16</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>8x1.25</Table.Cell>
            <Table.Cell>31</Table.Cell>
            <Table.Cell>31</Table.Cell>
            <Table.Cell>16</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Container>
    <ScrollToTopOnMount />
  </div>
)

export default PageDepthOfThreadF