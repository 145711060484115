import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Segment,
  Container,
  Grid,
  Header,
  List,
  Image
} from 'semantic-ui-react'


class Footer extends Component {


  renderNavLink = (navLink, key) => {
    const { openCatalogPage, openTechnicalPage, openCrossRefPage, openRFQPage } = this.props.shop.view;

    let openPage = () => {};

    if (navLink.url)
      return <List.Item key={key} as='a' onClick={()=> { window.location.assign(navLink.url); }} >{navLink.title}</List.Item>
    else {
      if (navLink.page == 'rfq')
        openPage = openRFQPage;
      else if (navLink.page == 'technical')
        openPage = openTechnicalPage;
      else if (navLink.page == 'crossref')
        openPage = openCrossRefPage;
      else if (navLink.page == 'homepopup')
        openPage = () => { alert('Clicking the \'Home\' link would bring you back to your existing home page'); };
      else
        openPage = openCatalogPage;

      return <List.Item as='a' key={key} onClick={openPage}>{navLink.title}</List.Item>
    }
  };

  renderNavLinkGroup = (navLinkGroup, key) => {
    console.log(navLinkGroup);
    return (
      <Grid.Column width={3}>
        <Header inverted as='h4' content={navLinkGroup.title} />
        <List link inverted>
          {navLinkGroup.navLinks.map((navLink, idx) => { return this.renderNavLink(navLink, idx) })}
        </List>
      </Grid.Column>)
  };

  render() {
    const { shop } = this.props
    const { openCatalogPage, openTechnicalPage, openCrossRefPage, openLocalRepsPage, openCreatePrintPage, openContactUsPage } = shop.view;

    console.log(shop.config.footerNavLinks);

    return (
      <Segment inverted vertical style={{ padding: '5em 0em' }} className="footer">
        <Container>
          <Grid divided inverted stackable>
            <Grid.Row>
              {shop.config.footerNavLinks.map((navLinkGroup, idx) => { return this.renderNavLinkGroup(navLinkGroup, idx) })}
              <Grid.Column width={5}>
                <Header as='h4' inverted>{shop.config.companyName}</Header>
                <p>{shop.config.companyTagLine}</p>
                <p>Copyright © 2020</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    )
  }
}


export default inject("shop")(observer(Footer))
