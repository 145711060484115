import { observer, inject } from "mobx-react"
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DesktopContainer from './DesktopContainer'
import MobileContainer from './MobileContainer'

const ResponsiveContainer = ({ children, shop }) => (
  <div className={'ResponsiveContainer page' + shop.view.page }>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

export default inject("shop")(observer(ResponsiveContainer))