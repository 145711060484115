import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Container,
  Divider,
  Grid,
  Image,
  Segment,
  Card,
  Header,
  Icon,
  Label,
  Flag,
  Message
} from 'semantic-ui-react'
import ScrollToTopOnMount from './ScrollToTopOnMount'
import USAMap from "react-usa-map";
import _ from 'underscore'

const repData = [
  {
    company: 'Rick Rudoph Associates',
    contact: 'Rick Rudolph',
    street: 'PO Box 760961',
    city: 'Melrose',
    state: 'MA',
    zip: '02176',
    phone: '617-240-5326',
    fax: '339-600-3004',
    mobile: '',
    email: 'rick@rickrudolphassociates.com',
    website: 'www.rickrudolphassociates.com',
    area: ['MA', 'CT', 'RI', 'NH', 'VT', 'ME'],
    location: { lat: 42.4572006, lon: -71.0716175 }
  },
  {
    company: 'All American Systems',
    contact: 'Steve Urhausen',
    street: '800 W. 5th Avenue - Suite 201D',
    city: 'Naperville',
    state: 'IL',
    zip: '60563',
    phone: '630-778-6887',
    fax: '630-778-8138',
    mobile: '630-258-8546',
    email: 'steve@allamericansystems.com',
    website: 'www.allamericansystems.com',
    area: ['IL', 'WI', 'IA', 'MN', 'IN', 'NE', 'MO', 'KS'],
    location: { lat: 41.7798442, lon: -88.1664693 }
  },
  {
    company: 'Binge and English',
    contact: '',
    street: '7200 E. Dry Creek Rd./Suite G-201',
    city: 'Centennial',
    state: 'CO',
    zip: '80012',
    phone: '720-489-7747',
    fax: '720-489-7797',
    mobile: '720-217-2773',
    email: '',
    contacts: [{name: 'Rob English', email: 'renglish46@aol.com', phone: '720-489-7747'}, {name: 'Neil Binge', email: 'binanden@aol.com', phone: '720-394-3539'}],
    website: '',
    area: ['CO', 'UT', 'NM', 'WY', 'AZ', 'NV'],
    location: { lat: 39.5798292, lon: -104.9072252 }
  },
 {
    company: 'Prowest Marketing',
    contact: 'Terry May',
    street: '16617 SE 35th Way',
    city: 'Vancouver',
    state: 'WA',
    zip: '98683',
    phone: '360-254-2679',
    fax: '360-282-5577',
    mobile: '360-921-3898',
    email: 'terrymay@prowestmkt.com',
    website: '',
    area: ['OR', 'WA', 'ID', 'AK'],
    location: { lat: 45.5951765, lon: -122.5031354 }
  },
  {
    company: 'Atlas Industry Marketing',
    contact: 'Alan Lindahl',
    street: '321 Plantation Dr. East',
    city: 'Burleson',
    state: 'TX',
    zip: '76028-1488',
    phone: '817-447-1750',
    fax: '817-447-1320',
    mobile: '817-247-8759',
    email: 'allindahl@sbcglobal.net',
    website: 'www.atlas-im.com',
    area: ['TX', 'OK', 'AR', 'LA'],
    location: { lat: 32.4985901, lon: -97.2745976 }
  },
  {
    company: 'John Gourley Associates',
    contact: 'Jack Gourley',
    street: '16 Lower Rd',
    city: 'Lakeville',
    state: 'PA',
    zip: '18438',
    phone: '570-226-7475',
    fax: '',
    mobile: '215-421-2937',
    email: 'jackgou@ptd.net',
    website: '',
    stateHeadings: {
      PA: 'Eastern PA'
    },
    area: ['PA', 'NJ', 'MD', 'DE'],
    location: { lat: 41.423831, lon: -75.2589127 }
  },
  {
    company: 'Ventura Industrial Products, LLC',
    contact: 'Jackie Ventura',
    street: '9520 S.Skyland Dr.',
    city: 'Macedonia',
    state: 'OH',
    zip: '44056',
    phone: '330-467-0102',
    fax: '330-467-0108',
    mobile: '216-338-8485',
    email: 'jv@jackieventura.com',
    website: '',
    stateHeadings: {
      NY: 'Upstate New York',
      PA: 'Western PA'
    },
    area: ['NY', 'MI', 'OH', 'KY', 'PA'],
    location: { lat: 41.338112, lon: -81.5006623 }
  },
  {
    company: 'Steers Associates',
    contact: 'Rob Steers',
    street: '610 Willow Knoll Drive',
    city: 'Marietta',
    state: 'GA',
    zip: '30067',
    phone: '404-964-8498',
    fax: '',
    mobile: '',
    email: 'rwsteers@aol.com',
    website: '',
    area: ['FL', 'AL', 'GA'],
    location: { lat: 33.941224, lon: -84.4186551 }
  },
  {
    company: 'S.A. Welch & Associates',
    contact: 'Stephen (Steve) Welch',
    street: '802 Worchester Place',
    city: 'Simpsonville',
    state: 'SC',
    zip: '29680',
    phone: '864-325-2451',
    fax: '888-366-5023',
    mobile: '',
    email: 'steveawelch@hotmail.com',
    website: '',
    area: ['VA', 'WV', 'NC', 'SC', 'TN'],
    location: { lat: 34.7090656, lon: -82.2880765 }
  },
  {
    company: 'CJRJ Sales Inc.',
    contact: 'Rick Jaszek',
    street: '604 - 478 Pearl St.',
    city: 'Burlington',
    state: 'Ontario',
    zip: '',
    country: 'Canada L7R 2N3',
    phone: '905-975-8896',
    fax: '',
    email: 'rpjaszek@sympatico.ca',
    website: '',
    area: ['Canada'],
    location: { lat: 43.3279756, lon: -79.7994629 }
  },
  {
    company: 'PMIeng- Mexico',
    contact: 'Francisco Seijas',
    street: '',
    city: '',
    state: '',
    zip: '',
    phone: '817-402-3603',
    fax: '',
    mobile: '011521-6562158461',
    email: 'francisco@pmieng.com',
    website: '',
    location: { lat: 28.6709132, lon: -106.134658 },
    area: ['Mexico']
  },
  {
    company: 'Fascomp Electronic Hardware',
    contact: 'Contact Fascomp for information on local reps in this area',
    street: '',
    city: '',
    state: '',
    zip: '',
    phone: '(407) 226-2112',
    fax: '(407) 226-3370',
    email: 'sales@fascomp.com',
    stateHeadings: {
      // CA: 'Southern California',
      NY: 'Metro New York'
    },
    area: ['CA', 'NY'],
    location: { lat: 32.4985901, lon: -97.2745976 }
  }
]

const noRepData = {
    company: 'Fascomp Electronic Hardware',
    contact: 'Contact Fascomp for information on local reps in this area',
    street: '',
    city: '',
    state: '',
    zip: '',
    phone: '(407) 226-2112',
    fax: '(407) 226-3370',
    email: 'sales@fascomp.com',
    // stateHeadings: { CA: 'Southern California'},
    area: null,
    location: { lat: 32.4985901, lon: -97.2745976 }
  }

const AnyReactComponent = ({ text }) => <div>{text}</div>;


const RepCard = ({ card, filter }) => {
  console.log('FILTER', filter)

  return (
    <Card>
      <Card.Content>
        <Card.Header>
          {card.company}
        </Card.Header>
        <Card.Description>
          {card.contact && <div>{card.contact}</div>}
          {card.street && <div>{card.street}</div>}
          {(card.city || card.state || card.zip) && <div>{card.city && <span>{card.city},</span>} {card.state && <span>{card.state}</span>} {card.zip && <span>{card.zip}</span>}</div>}
          {card.phone && <div>tel: {card.phone}</div>}
          {card.fax && <div>fax: {card.fax}</div>}
          {card.mobile && <div>cell: {card.mobile}</div>}
          {card.email && <div><a href={'mailto:' + card.email}>{card.email}</a></div>}
          {card.website && <div><a href={'http://' + card.website} target="_blank">{card.website}</a></div>}
          {card.contacts && <div>
            {card.contacts.map((contact, idx) => (
              <div key={'contacts_' + idx}>{contact.name} - <a href={'mailto:' + contact.email}>{contact.email}</a> - {contact.phone}</div>
            ))}
          </div>}
          {card.area && card.company !== 'Fascomp Electronic Hardware' && <div>Area:&nbsp;
            {card.area.map((area, idx) => (
              <span key={'area_' + idx}>{area}{idx < card.area.length - 1 ? ', ' : ''}</span>
            ))}
          </div>}
        </Card.Description>
      </Card.Content>
      {card.stateHeadings && card.stateHeadings[filter] && <Card.Content extra>{card.stateHeadings[filter]}</Card.Content>}
    </Card>
  )
}

const RepCards = ({cards, filter}) => (
    cards.map((card, idx) => (
      <RepCard card={card} filter={filter} key={'repCard_' + idx} />
)))

class PageLocalReps extends Component {

  state = {
    filter: null
  }

  handleMapClick = (event) => {
    const { filter } = this.state;

    console.log('EVENT TARGET:', event.target);

    //const name = event.target.dataset ? event.target.dataset.name : event.target.getAttribute('name');
    const name = event.target.getAttribute('data-name');

    if (filter == name)
      this.setState({ filter: null });
    else
      this.setState({ filter: name})
  };

  handleCountryButtonClick = (country) => {
    const { filter } = this.state;

    if (filter == country)
      this.setState({ filter: null });
    else
      this.setState({ filter: country})
  };

  get mapConfig() {

    const { filter } = this.state;
    const config = {};

    if (filter)
      config[filter] = { fill: "#3d1371" };

    return config;
  }

  get repData() {

    const { filter } = this.state;
    let filteredReps;

    if (filter)
    {
      filteredReps = repData.filter(rep =>  rep.area.indexOf(filter) > -1);
      if (filteredReps.length == 0)
        filteredReps.push(noRepData);

      return filteredReps;
    }

    return repData;
  }



  render() {
    const { filter } = this.state;

    console.log('FILTER RENDER', filter);

    return (
      <div className="pagelocalreps">
        <Container>
          <Header as='h2' dividing textAlign='center'>
            <Icon name='user' />
            <Header.Content>Sales Representatives</Header.Content>
          </Header>
          <Message
            icon='info circle'
            content='Select a state for your local sales representative'
          />
          <Segment textAlign='center' className="mapsegment">
            <div>
               <USAMap onClick={ this.handleMapClick } customize={ this.mapConfig } width="100%" height="" />
            </div>
            <div>
              <Label as='a' color={filter == 'Mexico' ? "violet" : ''} onClick={ () => this.handleCountryButtonClick('Mexico') }>
                <Flag name="mexico" /> Mexico
              </Label>
              <Label as='a' color={filter == 'Canada' ? "violet" : ''} onClick={ () => this.handleCountryButtonClick('Canada') }>
                <Flag name="canada" /> Canada
              </Label>
            </div>
          </Segment>


          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={16}>
                <Card.Group centered={true} stackable={true}>
                  <RepCards filter={filter} cards={this.repData} />
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <ScrollToTopOnMount />
      </div>
    );
  }
}


export default PageLocalReps