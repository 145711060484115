import { types, getParent, flow } from "mobx-state-tree"

export const OptionAttribute = types
  .model('OptionAttribute', {
    name: types.string,
    value: types.maybe(types.string)
  });

export const OptionTolerance = types
    .model('OptionTolerance', {
        name: types.string,
        tolerance: types.maybe(types.string)
    });

export const Option = types
  .model('Option', {
    name: types.string,
    value: types.maybe(types.string),
    sort: types.maybe(types.number),
    optionAttributes: types.array(OptionAttribute),
  });

export const OptionSet = types
  .model('OptionSet', {
    name: types.string,
    displayName: types.maybe(types.string),
    displayNameShort: types.maybe(types.string),
    displayType: types.maybe(types.string),
    options: types.optional(types.array(Option), [])
  });

export const PartOption = types
  .model('OptionAttribute', {
    name: types.string,
    value: types.maybe(types.string)
  });

export const Part = types
  .model("Part", {
    id: types.identifier(),
    partNumber: types.string,
    groupSort: types.maybe(types.number),
    optionAttributes: types.optional(types.array(OptionAttribute), []),
  });


export const Position = types
  .model("Position", {
    x: types.number,
    y: types.number
  });

export const Positions = types
  .model("Positions", {
    address: types.maybe(Position),
    phone: types.maybe(Position),
    website: types.maybe(Position),
    partNumber: types.maybe(Position),
    material: types.maybe(Position),
    finish: types.maybe(Position),
    a: types.maybe(Position),
    b: types.maybe(Position),
    c: types.maybe(Position),
    d: types.maybe(Position),
    e: types.maybe(Position),
    f: types.maybe(Position),
    g: types.maybe(Position),
    h: types.maybe(Position),
    l: types.maybe(Position),
    lb: types.maybe(Position),
    ld: types.maybe(Position),
    od: types.maybe(Position),
    length: types.maybe(Position),
    thread: types.maybe(Position),
    threada: types.maybe(Position),
    threadc: types.maybe(Position),
    threadDepth: types.maybe(Position),
    thickness: types.maybe(Position),
    diameter: types.maybe(Position),
    hexe: types.maybe(Position),
    cbore: types.maybe(Position),
    shank: types.maybe(Position),
    swage: types.maybe(Position),
    radius: types.maybe(Position),
    watermark: types.maybe(Position),
    logo: types.maybe(Position)
  });

export const PrintOptions = types
  .model("PrintOptions", {
    positions: Positions,
    template: types.string
  });

export const PartGroup = types
  .model("PartGroup", {
    id: types.identifier(),
    type: types.string,
    name: types.string,
    partNumberMask: types.optional(types.string, ''),
    urlName: types.optional(types.string, ''),
    oldUrlName: types.optional(types.string, ''),
    optionAttributes: types.optional(types.array(OptionAttribute), []),
    optionSets: types.optional(types.array(OptionSet), []),
    drawing: types.string,
    imgSrc: types.string,
    partOptions: types.optional(types.array(PartOption), []),
    parts: types.optional(types.array(Part), []),
    printOptions: types.maybe(PrintOptions),
    groupNotes: types.optional(types.array(types.string), []),
    optionTolerance: types.optional(types.array(OptionTolerance), []),
  });

export const PartSelected = types
  .model("PartSelected", {
    partNumber: types.string,
    description: types.string,
    selectedAttributes: types.optional(types.frozen),
    material: OptionAttribute,
    plating: OptionAttribute
  });

export const PartGroupStore = types
  .model("PartGroupStore", {
    isLoading: true,
    isLoadingSingle: true,
    activePartGroup: types.maybe(PartGroup),
    partGroups: types.map(PartGroup),
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    }
  }))
  .actions(self => {

    let partGoupLoadPromise;
    let partGoupSingleLoadPromise;

    function markLoading(loading) {
      self.isLoading = loading
    }

    function markLoadingSingle(loading) {
      self.isLoadingSingle = loading
    }

    function updatePartGroups(json) {
      json.forEach(photoJson => {
        self.partGroups.put(photoJson)
      })
    }

    function partGroupByUrlName(urlName) {

      /*
      self.partGroups.entries().forEach((pg) => {
        console.log('"/' + pg[1].oldUrlName + '": () => shop.view.openPartGroupPageByUrlName("' + pg[1].urlName + '"),');
      });
      */

      let found = self.partGroups.entries().find((pg) => {

        //console.log(pg[1].urlName, urlName);

        if (pg[1].urlName === urlName)
          return true;
        else
          return false;
      });

      console.log(found ? found[1] : undefined);

      return found ? found[1] : undefined;
      //return self.partGroups.entries().find(pg => pg[1].urlName === urlName);
    }

    function partGroupByPartNumber(partNumber) {
        const basePartNumber = partNumber.split('-')[0];

        const partsGroupsFound = self.partGroups.entries().filter((pg) => {
            const parts = pg[1].parts;

            for (let part of parts) {
                if (part.partNumber == basePartNumber.toUpperCase()) {
                    return true;
                }
            }
            return false;
        });

        return partsGroupsFound.length > 0 ? partsGroupsFound[0][1] : undefined;
    }

      function crossPartNumber(partNumberCross) {
          let crossedPart = partNumberCross.toUpperCase();

          // RAF cross reference
          if (crossedPart.substr(0,2) !== 'FC' && crossedPart.substr(0,2) !== 'FM')
          {
              if (crossedPart.substr(0,1) !== 'M')
                  crossedPart = 'FC' + crossedPart;
              else
                  crossedPart = 'F' + crossedPart;
          }

          if (crossedPart.substr(crossedPart.length - 3) == '-AL' || crossedPart.indexOf('-AL-') > -1)
              crossedPart = crossedPart.replace('-AL', '-A');

          return crossedPart;
      }

      function partGroupByPartNumberCross(partNumberCross) {
          let basePartNumber = crossPartNumber(partNumberCross);

          if (!basePartNumber)
              return undefined;

          basePartNumber = basePartNumber.split('-')[0];

          const partsGroupsFound = self.partGroups.entries().filter((pg) => {
              const parts = pg[1].parts;

              for (let part of parts) {
                  if (part.partNumber == basePartNumber.toUpperCase()) {
                      return true;
                  }
              }
              return false;
          });

          return partsGroupsFound.length > 0 ? partsGroupsFound[0][1] : undefined;
      }

    function getS(id) {
      self.activePartGroup = self.partGroups.get(id);
    }

    const _loadPartGroupByPartNumber = flow(function* loadPartGroupByPartNumber(partNumber) {
      try {
        const json = yield self.shop.fetch(`/dist/partgroupbypartnumber/${partNumber}`);
        updatePartGroups(json)
        markLoadingSingle(false)
      } catch (err) {
        console.error(`Failed to load partgroup ${partNumber}`, err)
      }
    })

    function loadPartGroupByPartNumber(partNumber, loadall) {
      if (partGoupLoadPromise)
        return partGoupLoadPromise;
      //else if(partGoupLoadByUrlPromise)
      //  return partGoupLoadByUrlPromise;
      markLoadingSingle(true);

      partGoupSingleLoadPromise = _loadPartGroupByPartNumber(partNumber);

      if (loadall)
        loadPartGroups();
        
      return partGoupSingleLoadPromise;
    }


    function loadPartGroupByCrossPartNumber(partNumber, loadall) {
      if (partGoupLoadPromise)
        return partGoupLoadPromise;
      //else if(partGoupLoadByUrlPromise)
      //  return partGoupLoadByUrlPromise;
      markLoadingSingle(true);

      partGoupSingleLoadPromise = _loadPartGroupByPartNumber(crossPartNumber(partNumber));

      if (loadall)
        loadPartGroups();
        
      return partGoupSingleLoadPromise;
    }


    const _loadPartGroupByUrl = flow(function* loadPartGroupByUrl(urlname) {
      try {
        const json = yield self.shop.fetch(`/dist/partgroupbyurl/${urlname}`);
        updatePartGroups(json)
        markLoadingSingle(false)
      } catch (err) {
        console.error(`Failed to load partgroup ${urlname}`, err)
      }
    })

    function loadPartGroupByUrl(urlname, loadall) {
      if (partGoupLoadPromise)
        return partGoupLoadPromise;
      //else if(partGoupLoadByUrlPromise)
      //  return partGoupLoadByUrlPromise;
      markLoadingSingle(true);

      partGoupSingleLoadPromise = _loadPartGroupByUrl(urlname);

      if (loadall)
        loadPartGroups();

      return partGoupSingleLoadPromise;
    }

    const _loadPartGroups = flow(function* loadPartGroups() {
      try {
        const json = yield self.shop.fetch("/dist/partgroups");
        updatePartGroups(json)
        markLoading(false)
        markLoadingSingle(false)
      } catch (err) {
        console.error("Failed to load partgroups ", err)
      }
    })

    function loadPartGroups() {
      if (partGoupLoadPromise)
        return partGoupLoadPromise;

      partGoupLoadPromise = _loadPartGroups();
      return partGoupLoadPromise;
    }

    function searchPartGroupsByPartNumber(partNumber, limit) {
      return loadPartGroups().then(() =>{

        if (partNumber.length > 2) {

          partNumber = partNumber.split('-')[0];

          /*
          if (partNumber.substring(0, 2).toUpperCase() == 'FC'
            || partNumber.substring(0, 2).toUpperCase() == 'FM') {

            partNumber = partNumber.split('-')[0];
          }
          else {
            // Strip all text, and only search the first 4 numbers
            partNumber = partNumber.replace(/[^0-9.]/g, "").substring(0, 4);
          }
          */
        }

        const partsGroupsFound = self.partGroups.entries().filter((pg) => {
          const parts = pg[1].parts;

          for (let part of parts) {
            //if (part.partNumber.substr(0, value.length) === value.toUpperCase()) {
            if (part.partNumber.indexOf(partNumber.toUpperCase()) > -1) {
              return true;;
            }
          }
          return false;
        });

        return partsGroupsFound;
      })
    }

    const searchPartGroups = flow(function* searchPartGroups() {
      yield self.partGoupLoadPromise;
    })

    return {
      updatePartGroups,
      loadPartGroups,
      loadPartGroupByUrl,
      loadPartGroupByPartNumber,
      loadPartGroupByCrossPartNumber,
      partGroupByUrlName,
      partGroupByPartNumber,
      searchPartGroupsByPartNumber,
      partGroupByPartNumberCross,
      crossPartNumber
    }
  })


export default PartGroupStore

        