import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { observer, inject } from "mobx-react"
import _ from 'underscore'
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
    Form,
Search
} from 'semantic-ui-react'
import './MobileContainer.css'
import HomepageHeading from './HomepageHeading'
import Footer from './Footer'

const initialState = {
    isLoading: false,
    results: [],
    value: ''
  }

class MobileContainer extends Component {
  state = initialState

  handlePusherClick = () => {
    const { sidebarOpened } = this.state

    if (sidebarOpened) this.setState({ sidebarOpened: false })
  }

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened })

  handleSearchChange = (e, { value }) => {
    const { searchPartGroupsByPartNumber } = this.props.shop.partGroupStore

    this.setState({ isLoading: true, value });
    const searchResults = [];

    searchPartGroupsByPartNumber(value).then((partsGroupsFound) => {

      partsGroupsFound.forEach((partGroup) => {
        searchResults.push({
          title: partGroup[1].name,
          image: "/images/partGroups/" + partGroup[1].imgSrc,
          urlName: partGroup[1].urlName
        });
      })

      this.setState({
        isLoading: false,
        value,
        results: searchResults
      })

    });
  }

  handleResultSelect = (e, { result }) => {
    const { openPartGroupPageByUrlName } = this.props.shop.view;
    const { shop } = this.props;
    const { value } = this.state;
    shop.setLastSelectedSearch(value);
    openPartGroupPageByUrlName(result.urlName);
  }

  renderNavLink = (navLink, key) => {
    const { page, openCatalogPage, openTechnicalPage, openCrossRefPage, openRFQPage } = this.props.shop.view;

    let openPage = () => {};
    let curpage = '';

    if (navLink.url)
      return <Menu.Item key={key} as='a' onClick={()=> { window.location.assign(navLink.url);  this.handlePusherClick()}} >{navLink.title}</Menu.Item>
    else {
      curpage = navLink.page;
      if (navLink.page == 'rfq')
        openPage = openRFQPage;
      else if (navLink.page == 'technical')
        openPage = openTechnicalPage;
      else if (navLink.page == 'crossref')
        openPage = openCrossRefPage;
      else if (navLink.page == 'homepopup')
        openPage = () => { alert('Clicking the \'Home\' link would bring you back to your existing home page'); };
      else {
        curpage = 'catalog';
        openPage = openCatalogPage;
      }

      return <Menu.Item as='a' key={key} onClick={() => {openPage();  this.handlePusherClick()}} active={page == curpage}>{navLink.title}</Menu.Item>
    }
  };

  render() {
    const { children, shop } = this.props
    const { page } = shop.view;
    const { isLoading, value, results, sidebarOpened } = this.state

    return (
      <Responsive {...Responsive.onlyMobile} className='MobileContainer'>
        <Menu fixed='top' inverted pointing secondary size='large' className='MobileMenu'>
          <Menu.Item onClick={this.handleToggle}>
            <Icon name='sidebar' />
          </Menu.Item>

          <Menu.Item position='right' className='partSearch'>
            <Search inverted  placeholder='Part Search...'
              loading={isLoading}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchChange, 500, {
                leading: true,
              })}
              results={results}
              value={value}
              {...this.props}/>
          </Menu.Item>
        </Menu>

        <Menu fixed='left' inverted secondary vertical size='large' className={'MobileMenuSide' + (sidebarOpened ? '' : ' closed')}>

          {shop.config.mobileNavLinks.map((navLink, idx) => { return this.renderNavLink(navLink, idx) })}

        </Menu>
        <Sidebar.Pushable>
          <Sidebar as={Menu} animation='uncover' inverted vertical visible={sidebarOpened}>

          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh' }}>
            {(page === 'home') && <Segment inverted textAlign='center' className='MobileSegment' vertical>
              <HomepageHeading mobile />
            </Segment>}
            <div className='mobileChildren'>
              {children}
              <Footer />
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

      </Responsive>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

export default inject("shop")(observer(MobileContainer))
